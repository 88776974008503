import React, { useEffect, useState } from "react";
import { ReactComponent as MenuIcon } from "../../assets/images/menu.svg";
import "./sidebar.css";
import { ConfigProvider, Flex, Tabs } from "antd";
import { ReactComponent as Collapse } from "../../assets/images/collapse.svg";
import AvatarList from "../sidebarList/avatarList/avatarList";
import AudioList from "../sidebarList/audioList/audioList";
import { LeftOutlined } from "@ant-design/icons";
import { PRIMARY_COLOR } from "../../utils/colors";
import {
  createGeneralChannel,
  getGeneralChannel,
} from "../../api/profileServices";
import ChatBot from "../chatbot/chatbot";
import { useOutletContext } from "react-router-dom";

const VideoSidebar = ({
  voiceId,
  setVoiceId,
  avatarId,
  setAvatarId,
  setVoiceChanged,
  current,
  diyVideo = false,
}) => {
  const [selected, setSelected] = useState("");
  const [channel, setchannel] = useState("");
  const [, , , , userDetails] = useOutletContext();

  useEffect(() => {
    getGeneralChannel()
      .then((res) => {
        setchannel(res.data._id);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message === "Failed to fetch the channel") {
          createGeneralChannel()
            .then((res) => {
              setchannel(res.data._id);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  }, []);
  const items = [
    {
      key: "1",
      label: (
        <Flex vertical align="center" className="item">
          <MenuIcon className="menu-logo" />
          Chat
        </Flex>
      ),
      children: (
        <ChatBot
          channel={channel}
          type={"Video"}
          userDetails={userDetails || {}}
        />
      ),
    },
    {
      key: "2",
      label: (
        <Flex vertical align="center" className="item">
          <MenuIcon className="menu-logo" />
          Avatar
        </Flex>
      ),
      children: <AvatarList setAvatarId={setAvatarId} avatarId={avatarId} />,
    },
    {
      key: "3",
      label: (
        <Flex vertical align="center" className="item">
          <MenuIcon className="menu-logo" />
          <span>
            Audio
            <br /> Profile
          </span>
        </Flex>
      ),
      children: (
        <AudioList
          setVoiceId={setVoiceId}
          voiceId={voiceId}
          setVoiceChanged={setVoiceChanged}
          current={current}
          diyVideo={diyVideo}
        />
      ),
    },
  ];
  const onChange = (key) => {
    setSelected(key);
  };
  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            darkItemBg: "#FFFFFF",
            darkItemColor: PRIMARY_COLOR,
            darkItemSelectedBg: PRIMARY_COLOR,
            darkItemSelectedColor: "#FFFFFF",
            itemMarginInline: "0",
            itemPaddingInline: 10,
            fontSize: 12,
            fontWeightStrong: 400,
            darkItemHoverColor: PRIMARY_COLOR,
            darkItemHoverBg: "#0000000f",
          },
          Drawer: {
            // footerPaddingBlock: 30,
            paddingLG: 10,
          },
        },
      }}
    >
      <Flex align="flex-start" className="video-sidebar">
        <Flex className="menu-items">
          <Tabs
            className={`video-tab ${selected ? "active" : ""}`}
            type="card"
            animated={false}
            tabPosition={"left"}
            items={items}
            activeKey={selected}
            onChange={onChange}
          />
        </Flex>
        <Flex>
          {selected && (
            <div className="collapse" onClick={() => setSelected("")}>
              <div className="collapse-icon">
                <LeftOutlined />
              </div>
              <Collapse />
            </div>
          )}
        </Flex>
      </Flex>
    </ConfigProvider>
  );
};

export default VideoSidebar;
