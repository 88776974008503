import { Button, Checkbox, Divider, Drawer, Empty, Input } from "antd";
import React, { useEffect, useState } from "react";
import "./modal.css";
import { getCategories } from "../../api/adminService";

const VideosFilterDrawer = ({
  isDrawerOpen,
  handleCancel,
  setfilter,
  typeFilter,
  stateFilter,
  setVideosPage,
}) => {
  const [categories, setCategories] = useState([]);
  const [type, setType] = useState(typeFilter ?? "");
  const [state, setState] = useState(stateFilter ?? "");
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  // const [category, setCategory] = useState(categoryFilter ?? []);
  const filterType = ["TRAINING", "PERSONALISED", "DIY", "LESSON", "DEMO"]
  const [currentPage, setcurrentPage] = useState(1);
  const [total, setTotal] = useState();
  const [initial, setinitial] = useState(true);
  let debounceTimeout;
  const debouncedSearch = (input) => {
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      setcurrentPage(1);
      setSearchKey(input);
    }, 500);
  };

  const onSubmit = () => {
    setfilter({
      type: type,
      state: state,
      // category: category,
      active: type || state,
    });
    setVideosPage(1);
    handleCancel();
  };
  const onSearch = (event) => {
    debouncedSearch(event.target.value);
  };
  // const onCategoryChange = (value) => {
  //   setCategory(value);
  // };
  const onModalClose = () => {
    handleCancel();
  };
  const onClearFilter = () => {
    setType("");
    setState("");
    // setCategory([]);
    setSearchKey("");
    setcurrentPage(1);
    setfilter({ type: "", state: "", active: false });
    fetchCategories();
  };

  const fetchCategories = () => {
    getCategories(searchKey, "", "", currentPage)
      .then((res) => {
        const responseData = res?.data.map((item) => ({
          label: item.name,
          value: item._id,
        }));
        if (initial || ((searchKey || searchKey === "") && currentPage === 1)) {
          setCategories(responseData);
        } else {
          setCategories((prevCategories) => [
            ...prevCategories,
            ...responseData,
          ]);
        }
        setTotal(res?.info?.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
    setinitial(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchCategories();
  }, [currentPage, searchKey]);

  const onscroll = (e) => {
    const { target } = e;
    const bottom =
      target.scrollHeight - target.scrollTop <= target.clientHeight + 1;
    if (bottom && categories?.length < total && !loading) {
      setcurrentPage((prev) => prev + 1);
    }
  };
  return (
    <Drawer
      width={450}
      title="Filter"
      open={isDrawerOpen}
      onClose={onModalClose}
      footer={
        <div className="action-buttons">
          <Button type="primary" onClick={onSubmit}>
            Apply Filter
          </Button>
          <Button className="cancel-button" onClick={onClearFilter}>
            Clear all
          </Button>
        </div>
      }
    >
      <div className="drawer-content-wrapper">
        <div className="type-filter">
          <h3>Type</h3>
          <div className="type-filter-buttons">
          {filterType.map((item)=>(
            <Button
              key={item}
              type={`${type === item ? "primary" : "default"}`}
              onClick={() => setType(item)}
            >
              {item}
            </Button>
          ))}
          </div>
          <Divider />
        </div>
        <div className="type-filter">
          <h3>State</h3>
          <div className="type-filter-buttons">
            <Button
              type={"default"}
              onClick={() => setState("CREATED")}
            >
              CREATED
            </Button>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default VideosFilterDrawer;