import React, { useEffect, useState } from "react";
import { generateVideo } from "../../../api/hubSpotService";
import { Button, Col, Flex, Progress, Row } from "antd";
import { downloadLessonVideo } from "../../../api/adminService";

const LessonVideo = ({
  videoId,
  status,
  lesson,
  lessons,
  setLesson,
  setsectionStep,
  updateStep,
  setcurrentStatus,
  handleChangeSectionStep,
}) => {
  const [downloading, setDownloading] = useState(false);
  const getProgressPercentage = (status) => {
    if (status?.status?.toLowerCase() === "processing") {
      return "0";
    }
    if (status?.status?.toLowerCase() === "uploading") {
      return "98";
    }
    if (status?.status?.includes("out of")) {
      const [currentStep, totalSteps] = status?.status
        .split(" out of ")
        .map((num) => parseInt(num, 10));
      if (!isNaN(currentStep) && !isNaN(totalSteps) && totalSteps > 0) {
        const percentage = (currentStep / totalSteps) * 100;
        return `${percentage.toFixed(0)}`;
      }
    }
    if (status?.status?.toLowerCase() === "completed") {
      return "100";
    }

    return "0";
  };

  const getNextLesson = () => {
    const currentIndex = lessons?.findIndex((l) => l.id === lesson?._id);
    return lessons[currentIndex + 1] || null; // Get the next lesson if it exists
  };

  const handleNextLesson = () => {
    const nextLesson = getNextLesson();

    if (nextLesson) {
      setsectionStep(nextLesson);
      //   updateStep("lesson", nextLesson.id);
      handleChangeSectionStep(nextLesson);
      setLesson(nextLesson); // Update to the next lesson
    }
  };

  const nextLessonExists = getNextLesson() !== null;

  useEffect(() => {
    if (videoId && lesson.state === "AUDIO_GENERATION_COMPLETED") {
      setcurrentStatus("VIDEO_GENERATION_STARTED");
      const body = {
        videoId: videoId,
        // avatarId: avatarId,
      };
      generateVideo(body)
        .then((res) => {})
        .catch((err) => {});
    }
  }, [videoId, lesson]);

  const handleDownload = async () => {
    setDownloading(true);
    downloadLessonVideo(videoId)
      .then((res) => {
        const videoUrl = res?.data?.url;
        if (videoUrl) {
          setDownloading(false);
          const link = document.createElement("a");
          link.href = videoUrl;
          link.setAttribute("download", "video.mp4");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((err) => {
        console.log(err);
        setDownloading(false);
      });
  };
  return (
    <Flex vertical gap={20} align="center" justify="center" flex={1}>
      {lesson?.state === "VIDEO_GENERATION_COMPLETED" ? (
        <div className="preview-container" style={{ width: "100%" }}>
          <div className="preview">
            <iframe
              title="video-player"
              src={lesson?.videoData?.url ?? status?.url}
              loading="lazy"
              style={{
                border: "none",
                position: "absolute",
                top: 0,
                right: 0,
                height: "100%",
                width: "100%",
              }}
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowFullScreen
              onError={(e) => console.error("Error loading video:", e)}
            ></iframe>
          </div>
        </div>
      ) : (
        <Flex
          //   flex={1}
          className="course-content"
          align="center"
          vertical
          gap={20}
        >
          <Progress type="circle" percent={getProgressPercentage(status)} />
          <h2>Video Generation In Progress</h2>
          {nextLessonExists && (
            <p>
              You can go back and start with the next lesson. We will complete
              the video in the background and let you know.
            </p>
          )}
        </Flex>
      )}
      <Flex gap={20}>
        {nextLessonExists && (
          <Button onClick={handleNextLesson}>Start Creating Next Lesson</Button>
        )}
        {lesson?.state === "VIDEO_GENERATION_COMPLETED" && (
          <Button onClick={handleDownload} loading={downloading}>
            {downloading ? "Downloading Video" : "Download Video"}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default LessonVideo;
