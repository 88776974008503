import React, { useState, useEffect } from "react";
import { Button, Flex, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { generateVideo } from "../../../api/hubSpotService";
import { endAt, limitToLast, onChildAdded, ref } from "firebase/database";
import { dataBase } from "../../firebase/firebase";
import { query } from "firebase/firestore";
import NotificationInstance from "../../../services/notificationServices";

const DIYResult = ({ videoId, lang, diy }) => {
  const [status, setStatus] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setprogress] = useState(false);

  useEffect(() => {
    let unsubscribe;
    if (videoId) {
      const channelRef = query(
        ref(dataBase, `leads/videos/${videoId}`),
        endAt,
        limitToLast(1)
      );
      unsubscribe = onChildAdded(channelRef, (snapshot) => {
        const message = snapshot.val();
        if (message?.status === "completed") {
          setTimeout(() => {
            setVideoUrl(message?.url);
            setStatus("completed");
          }, 5000);
        } else {
          setStatus(message.status);
        }
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [videoId]);

  const getStatusText = () => {
    if (status === null) return diy[lang].videoResult.initial;
    if (status === "processing") return diy[lang].videoResult.process;
    if (status === "uploading") return diy[lang].videoResult.final;
  };
  return (
    // <div className="video-generation-container">
    //   {!videoUrl && (
    //     <Flex justify="center" align="center" vertical gap={30}>
    //       <Spin
    //         indicator={
    //           <LoadingOutlined style={{ fontSize: 40, color: "#000" }} spin />
    //         }
    //       />
    //       <p className="result-desc">{diy[lang].videoResult.description}</p>
    //       <h2 className="result-progress">{getStatusText()}</h2>
    //     </Flex>
    //   )}
    //   {videoUrl && (
    //     <div className="preview-container">
    //       <h1>🎉🎉 {diy[lang].videoResult.label}!!</h1>
    //       <div className="preview">
    //         <iframe
    //           title="video-player"
    //           src={`${videoUrl}`}
    //           loading="lazy"
    //           style={{
    //             border: "none",
    //             position: "absolute",
    //             top: 0,
    //             right: 0,
    //             height: "100%",
    //             width: "100%",
    //           }}
    //           allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
    //           allowFullScreen
    //           onError={(e) => console.error("Error loading video:", e)}
    //         ></iframe>
    //       </div>
    //       {/* <Flex justify="flex-end">
    //         <Button type="primary">Share Video</Button>
    //       </Flex> */}
    //     </div>
    //   )}
    // </div>
    <Flex vertical align="center" justify="center">
      <h2>{diy[lang].videoResult.description1}</h2>
      <p
        dangerouslySetInnerHTML={{ __html: diy[lang].videoResult.description2 }}
      />

      <h3>{diy[lang].videoResult.description3}</h3>
    </Flex>
  );
};

export default DIYResult;
