import React, { useState, useEffect } from "react";
import "./createVideo.css";
import { Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { generateVideo } from "../../../api/hubSpotService";
import { endAt, limitToLast, onChildAdded, ref } from "firebase/database";
import { dataBase } from "../../firebase/firebase";
import { query } from "firebase/firestore";
import NotificationInstance from "../../../services/notificationServices";

const CreateVideo = ({
  videoId,
  avatarId = null,
  updated,
  setUpdated,
  setGenerating,
  setVideoStatus,
}) => {
  const [status, setStatus] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setprogress] = useState(false);

  useEffect(() => {
    let unsubscribe;
    console.log(videoId, progress, updated);

    if (videoId && (progress || !updated)) {
      const channelRef = query(
        ref(dataBase, `leads/videos/${videoId}`),
        endAt,
        limitToLast(1)
      );
      unsubscribe = onChildAdded(channelRef, (snapshot) => {
        const message = snapshot.val();
        console.log(message, message);

        setStatus(message.status);
        if (message?.status === "completed") {
          setStatus("completed");
          setVideoStatus("completed")
          setIsLoading(false);
          setVideoUrl(message.url);
          setUpdated(false);
          setprogress(false);
          setGenerating(false);
        }
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [videoId, progress]);

  const handleGenerateVideo = async () => {
    setIsLoading(true);
    setGenerating(true);
    try {
      const body = {
        videoId: videoId,
        avatarId: avatarId,
      };
      await generateVideo(body);
      setprogress(true);
      setVideoStatus("started")
    } catch (error) {
      setIsLoading(false);
      setGenerating(false);
      NotificationInstance.error({
        message: "Error generating Video",
        description: "Please try again later",
      });
    }
  };

  const getStatusText = () => {
    if (status === null) return "Preparing...";
    if (status === "processing")
      return "Generating video... Usually it takes about 5 minutes";
    if (status === "uploading") return "Getting ready ...";
    if (status) return status;
  };
  return (
    <div className="video-generation-container">
      {((!videoUrl && !updated) ||
        (videoUrl && updated) ||
        (!videoUrl && updated)) && (
        <>
          {isLoading && (
            <div>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
              <p>{getStatusText()}</p>
            </div>
          )}
          <Button
            type="primary"
            onClick={handleGenerateVideo}
            disabled={isLoading}
          >
            Generate Video
          </Button>
        </>
      )}
      {videoUrl && !updated && (
        <div className="preview-container">
          {videoUrl && !isLoading && (
            <div className="preview">
              <iframe
                title="video-player"
                src={`${videoUrl}`}
                loading="lazy"
                style={{
                  border: "none",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  height: "100%",
                  width: "100%",
                }}
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowFullScreen
                onError={(e) => console.error("Error loading video:", e)}
              ></iframe>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CreateVideo;
