export const timeSinceLastSaved = (savedTime) => {
  const currentTime = new Date();
  const diffInMilliseconds = currentTime - savedTime;
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInDays > 0) {
    return `${diffInDays} day${diffInDays !== 1 ? "s" : ""} ago`;
  } else if (diffInHours > 0) {
    return `${diffInHours} hour${diffInHours !== 1 ? "s" : ""} ago`;
  } else {
    return `${diffInMinutes} min${diffInMinutes !== 1 ? "s" : ""} ago`;
  }
};
export const timeSinceLastUpdated = (savedTime) => {
  const currentTime = new Date();
  const parsedSavedTime = new Date(savedTime);

  if (isNaN(parsedSavedTime.getTime())) {
    // Handle invalid date format
    return "Invalid date";
  }

  const diffInMilliseconds = currentTime - parsedSavedTime;
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInDays > 0) {
    return `${diffInDays} day${diffInDays !== 1 ? "s" : ""} ago`;
  } else if (diffInHours > 0) {
    return `${diffInHours} hour${diffInHours !== 1 ? "s" : ""} ago`;
  } else {
    return `${diffInMinutes} min${diffInMinutes !== 1 ? "s" : ""} ago`;
  }
};
export const timeCreatedOn = (savedTime) => {
  if (savedTime) {
    const date = new Date(savedTime);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  } else {
    return "_";
  }
};
export const textFormat = (text) => {
  const formattedText = text?.replace(/\s+/g, " ").trim();
  return formattedText;
};
export const validateInput = (rule, value) => {
  if (!value) {
    return Promise.resolve();
  } else if (value.trim() === "") {
    return Promise.reject(rule);
  } else {
    return Promise.resolve();
  }
};

export const isLinkValid = (rule, inputValue) => {
  const urlPattern = /^(http(?:s)?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  if (!inputValue) {
    return Promise.resolve();
  } else if (!urlPattern.test(inputValue)) {
    return Promise.reject(rule);
  } else {
    return Promise.resolve();
  }
};

export const scriptStages = [
  "LESSON_SCRIPT_SUBMITTED",
  "SCRIPT_GENERATION_IN_PROGRESS",
  "AUDIO_GENERATION_INPROGRESS",
  "AUDIO_GENERATION_COMPLETED",
  "VIDEO_GENERATION_STARTED",
  "VIDEO_GENERATION_COMPLETED",
];
export const videoStages = [
  "VIDEO_GENERATION_STARTED",
  "VIDEO_GENERATION_COMPLETED",
];

export const audioStages = [
  "LESSON_SCRIPT_COMPLETED",
  "AUDIO_GENERATION_COMPLETED",
  "VIDEO_GENERATION_STARTED",
  "VIDEO_GENERATION_COMPLETED",
];

export const audioSection = [
  "LESSON_SCRIPT_SUBMITTED",
  "AUDIO_GENERATION_INPROGRESS",
  "AUDIO_GENERATION_COMPLETED",
];
export const scriptUpdateStages = [
  "AUDIO_GENERATION_INPROGRESS",
  "AUDIO_GENERATION_COMPLETED",
  "VIDEO_GENERATION_STARTED",
  "VIDEO_GENERATION_COMPLETED",
];
export const videoTypes = [
  { label: "Training", value: "TRAINING" },
  { label: "Personalized", value: "PERSONALIZED" },
  { label: "DIY", value: "DIY" },
];
