import React, { useState, useEffect } from "react";
import { ConfigProvider, Empty, Table, Tag } from "antd";
import "./videoListTable";
import { getVideoList } from "../../api/hubSpotService";
import { timeCreatedOn } from "../../utils/format";
import VideoPlayer from "../modals/videoPlayer";

const VideoLibraryTable = ({ type }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selecteddata, setSelectedData] = useState();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetchPersonalizedVideos = async (type) => {
      setLoading(true);
      try {
        const res = await getVideoList(type);
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    if (type) {
      fetchPersonalizedVideos(type);
    }
  }, [type]);

  const columns = [
    {
      title: "Title",
      dataIndex: type === "Journey" ? "title" : "script",
      key: "title",
      render: (text) => <p>{getTitle(text)}</p>,
    },
    {
      title: "User",
      dataIndex: "firstname",
      key: "user",
      render: (text, record) => {
        return `${record.firstname} ${record.lastname}`;
      },
      hidden: type === "Training" || type === "Journey",
    },
    {
      title: "Journey Type",
      dataIndex: "journeyType",
      key: "journeyType",
      hidden: type !== "Journey",
    },
    {
      title: "Video URL",
      dataIndex: "url",
      key: "url",
      render: (text, record) => {
        return record.status === "completed" ||
          record.uploadStatus === "SUCCESS" ? (
          <span
            className="video-link"
            onClick={() => {
              setSelectedData(record);
              setIsOpen(true);
            }}
          >
            View
          </span>
        ) : (
          <Tag>{record.status}</Tag>
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      hidden: type !== "Journey",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <p>{timeCreatedOn(text)}</p>,
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (text, record) => (
    //     <Button
    //       onClick={() => handleCopyLink(record.url)}
    //       style={{ marginRight: 8 }}
    //     >
    //       Copy Link
    //     </Button>
    //   ),
    // },
  ];

  const getTitle = (script) => {
    return script
      ? script?.split(" ").slice(0, 8).join(" ") + "..."
      : "No Title";
  };

  return (
    <div className="video-list-table">
      <ConfigProvider
        theme={{
          token: {
            controlInteractiveSize: 20,
          },
          components: {
            Table: {
              headerBorderRadius: "8px",
              headerColor: "#6A6A6A",
            },
            Checkbox: {
              colorPrimary: "#6A6A6A",
              colorPrimaryHover: "#6A6A6A",
            },
          },
        }}
      >
        {isOpen && (
          <VideoPlayer
            isOpen={isOpen}
            data={selecteddata}
            setIsOpen={setIsOpen}
            type={type}
          />
        )}
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          locale={{
            emptyText: (
              <Empty description={loading ? "loading" : "No Videos Found"} />
            ),
          }}
        />
      </ConfigProvider>
    </div>
  );
};

export default VideoLibraryTable;
