import "./App.css";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import DashboardLayout from "./components/layout/dashboardLayout";
import Login from "./pages/login/login";
import Journey from "./pages/journey/journey";
import { useContext, useEffect, useState } from "react";
import AuthContext from "./services/storageService";
import AllJourneys from "./pages/allJourneys/allJourneys";
import AllVideos from "./pages/allVideos/allVideos";
import CategoryJourneys from "./pages/categoryJourneys/categoryJourneys";
import Users from "./pages/users/users";
import Categories from "./pages/categories/categories";
import TrainingVideoList from "./pages/trainingVideoList/TrainingVideoList";
import PersonalizedVideo from "./pages/generatevideo/GenerateVideo";
import TrainingVideo from "./pages/generateTrainingVideo/TrainingVideo";
import VideoPreview from "./pages/videoPreview/videoPreview";
import PersonalizedVideoUsers from "./pages/personalizedVideoUsers/PersonalizedVideoUsers";
import VideoLibrary from "./pages/videoLibrary/VideoLibrary";
import UpdatePassword from "./pages/login/forgotPassword";
import ResetPassword from "./pages/login/resetPassword";
import DiyVideo from "./pages/generateScriptVideo/scriptVideo";
import DiyVideoList from "./pages/diyVideoList/diyVideoList";
import DIYDemo from "./pages/DIYDemo/DIYDemo";
import DIYDemoVideo from "./pages/DIYDemoVideo/DIYDemoVideo";
import PlaceHolder from "./pages/placeHolder/placeHolder";
import CourseVideoList from "./pages/courseList/courseList";
import CourseGeneration from "./pages/courseGeneration/courseGeneration";

function App() {
  const auth = useContext(AuthContext);
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 1200);
  useEffect(() => {
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const publicRoutes = createBrowserRouter([
    {
      path: "/video/:videoId",
      element: <VideoPreview />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/forgot-password",
      element: <UpdatePassword />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/demo/:language",
      element: <DIYDemo />,
    },
    {
      path: "/demo/:language/:demoId",
      element: <DIYDemoVideo />,
    },
    {
      path: "/demo/video/:videoId",
      element: <VideoPreview />,
    },
    {
      path: "*",
      element: <Navigate to="/login" />,
    },
  ]);

  const privateRoutes = createBrowserRouter([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          path: "/",
          element: <AllJourneys />,
        },
        {
          path: "/users",
          element: <Users />,
        },
        {
          path: "/categories",
          element: <Categories />
        },
        {
          path: "/:categoryId",
          element: <CategoryJourneys />,
        },
        {
          path: "/personalized-video",
          element: <PersonalizedVideoUsers />,
        },
        {
          path: "/personalized-video/new/:hubspotId",
          element: <PersonalizedVideo />,
        },
        {
          path: "/training-video",
          element: <TrainingVideoList />,
        },
        {
          path: "/training-video/new",
          element: <TrainingVideo />,
        },
        {
          path: "/diy-video",
          element: <DiyVideoList />,
        },
        {
          path: "/diy-video/new",
          element: <DiyVideo />,
        },
        {
          path: "/video-library",
          element: <VideoLibrary />,
        },
        {
          path: "/courses",
          element: <CourseVideoList />,
        },
        {
          path: "/videos",
          element: <AllVideos />,
        },
        {
          path: "/:categoryId/:operation/:journeyId",
          element: <Journey />,
        },
      ],
    },
    {
      path: "/demo/:language",
      element: <DIYDemo />,
    },
    {
      path: "/demo/:language/:demoId",
      element: <DIYDemoVideo />,
    },
    {
      path: "/demo/video/:videoId",
      element: <VideoPreview />,
    },
    
    {
      path: "/courses/create",
      element: <CourseGeneration />,
    },
    {
      path: "/courses/:courseIds",
      element:  <CourseGeneration />,
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);

  return auth.token ? (
    isScreenSmall && !window.location.pathname.includes("/demo") ? (
      <PlaceHolder />
    ) : (
      <RouterProvider router={privateRoutes} />
    )
  ) : (
    <RouterProvider router={publicRoutes} />
  );
}

export default App;
