import {
  Button,
  Col,
  Divider,
  Empty,
  Flex,
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Spin,
  Tooltip,
  Upload,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  generateLessonScript,
  getLessonAudios,
  getLessonDetails,
  submitCourseBriefingAnswers,
  submitCourseBriefingScript,
  SubmitLessonScript,
  updateLessonDetails,
  updateScriptParts,
} from "../../../api/adminService";
import { upload } from "@testing-library/user-event/dist/upload";
import LessonDocument from "./lessonDocument";
import LessonScriptUpdation from "../lessonScriptUpdation/lessonScriptUpdation";

import { ReactComponent as Video } from "../../../assets/images/videoPrompt.svg";
import { ReactComponent as Subtitle } from "../../../assets/images/subtitle.svg";
import { ReactComponent as Slide } from "../../../assets/images/slide.svg";
import LessonScript from "../lessonScript/lessonScript";
import LessonAudio from "../lessonAudio/lessonAudio";
import LessonVideo from "../lessonVideo/lessonVideo";
import { endAt, limitToLast, onChildAdded, ref } from "firebase/database";
import { dataBase } from "../../firebase/firebase";
import { query } from "firebase/firestore";
import UploadVideoPresentation from "../../modals/uploadVideoPresentation";
import { uploadPPT } from "../../../api/hubSpotService";
import NotificationInstance from "../../../services/notificationServices";
import {
  scriptStages,
  scriptUpdateStages,
  videoStages,
} from "../../../utils/format";
import "./lessonDetails.css";
const LessonDetails = ({
  type,
  questions,
  step,
  setIsDetailBoxOpen,
  courseId,
  setContentStep,
  setStatus,
  sectionStep,
  updateStatus,
  isDetailBoxOpen,
  lessons,
  setsectionStep,
  updateStep,
  lessonId,
  handleChangeSectionStep,
  setLessons,
  updateLessonStep,
  addVideoId,
}) => {
  const { TextArea } = Input;
  const { Dragger } = Upload;
  const [form] = Form.useForm();
  const [lesson, setLesson] = useState();
  const [lessonLoading, setLessonLoading] = useState(false);

  const [lessonForm] = Form.useForm();
  const [lessonScriptForm] = Form.useForm();
  const [updated, setUpdated] = useState(false);
  const [scriptUpdated, setScriptUpdated] = useState(false);
  const [selectedChunkIndex, setSelectedChunkIndex] = useState(null);
  const [selectedText, setSelectedText] = useState("");
  const [currentSubtitle, setCurrentSubtitle] = useState("");
  const [currentPrompt, setCurrentPrompt] = useState("");
  const [final, setFinal] = useState(false);
  const [section, setSection] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState("");
  const [file, setFile] = useState("");
  const [loading, setloading] = useState(false);
  const [pages, setPages] = useState(0);
  let nextStepShown = false;
  const [currentStep, setcurrentStep] = useState("script");
  const [currentStatus, setcurrentStatus] = useState("script");
  const [chunks, setChunks] = useState([
    { text: "", type: "normal", order: 0 },
  ]);

  const [progress, setProgress] = useState();
  const [scriptListner, setScriptListner] = useState(false);

  const [videoId, setVideoId] = useState();
  const [audioUrlsByLesson, setAudioUrlsByLesson] = useState({});

  const [finalScriptUpdated, setFinalScriptUpdated] = useState(false);
  const [selectedScript, setSelectedScript] = useState();

  const [slidePreview, setSlidePreview] = useState();
  const [regenerating, setRegenerating] = useState([]);
  const [confirmScript, setConfirmScript] = useState(false);
  const regenerateRef = useRef(null);

  const items = [
    {
      key: "subtitle",
      icon: <Subtitle />,
      label: "Add Subtitle",
    },
    // {
    //   key: "video",
    //   icon: <Video />,
    //   label: "Add video",
    // },
    {
      key: "slide",
      icon: <Slide />,
      label: "Slide Overlay",
    },
    {
      key: "upload",
      icon: <Slide />,
      label: "Upload Slide",
    },
  ];

  const onSubmit = (type, category, recreate) => {
    if (category === "lessonScript") {
      if (currentStep === "script") {
        lessonForm
          .validateFields()
          .then((values) => {
            setConfirmScript(true);
            let processedChunks = [];

            chunks.forEach((chunk) => {
              if (chunk.type === "normal") {
                // Split by double line breaks (\n\n) or more
                let splitTexts = chunk.text
                  .split(/\n{2,}/)
                  .filter((text) => text.trim() !== "");

                // Helper function to count sentences based on punctuation
                const countSentences = (text) => {
                  return (text.match(/[.!?](?=\s|$)/g) || []).length;
                };

                // Process text chunks
                splitTexts = splitTexts.reduce((acc, text, index, arr) => {
                  const sentenceCount = countSentences(text);

                  if (sentenceCount < 2 && index < arr.length - 1) {
                    // If fewer than 2 sentences and there's a next chunk, merge with the next
                    const nextText = arr[index + 1];
                    acc.push(text + " " + nextText); // Merge current and next text
                    arr.splice(index + 1, 1); // Remove the next one as it's now merged
                  } else {
                    // Otherwise, add the text as is
                    acc.push(text);
                  }

                  return acc;
                }, []);

                // Add the processed chunks back with updated order
                splitTexts.forEach((text) => {
                  processedChunks.push({
                    ...chunk,
                    text: text.trim(),
                    order: processedChunks.length,
                  });
                });
              } else if (
                chunk.type === "subtitle" ||
                chunk.type === "video" ||
                chunk.type === "slide"
              ) {
                processedChunks.push({
                  ...chunk,
                  text: chunk.text.replace(/\n+/g, " ").trim(),
                  order: processedChunks.length,
                });
              }
            });
            const mergedScript = processedChunks
              .map((chunk) => chunk.text)
              .join(" ");
            const answers = {
              finalScript: mergedScript,
            };
            updateLessonDetails(courseId, lesson?._id, answers)
              .then((res) => {
                updateScriptParts(courseId, lesson?._id, {
                  scriptParts: processedChunks,
                })
                  .then((res) => {
                    setConfirmScript(false);
                    setLesson(res.data);
                    updateStatus(sectionStep.id, "LESSON_SCRIPT_SUBMITTED");
                    updateLessonStep("AUDIO_GENERATION_INPROGRESS");
                    setcurrentStatus("LESSON_SCRIPT_SUBMITTED");
                    setAudioUrlsByLesson({});
                    setScriptUpdated(false);
                    setLesson((prev) => ({
                      ...prev,
                      finalScript: mergedScript,
                    }));
                    // setScript(mergedScript);
                    setChunks(processedChunks);
                    // next();
                    lessonForm.setFieldsValue(processedChunks);
                    setcurrentStep("audio");
                  })
                  .catch((err) => {
                    setConfirmScript(false);
                  });
              })
              .catch((err) => {
                setConfirmScript(false);
              });
          })
          .catch((err) => {
            console.log("Validation failed:", err);
          });
      } else {
        updateScriptParts(courseId, lesson?._id, {
          scriptParts: chunks,
        })
          .then((res) => {
            setContentStep((prev) => prev + 1);
          })
          .catch((err) => {});
      }
    } else if (category === "lessonForm" && !final) {
      lessonScriptForm
        .validateFields()
        .then((values) => {
          const { title, userContent } = values;
          const resetStepResults = Object.keys(lesson.stepResults).reduce(
            (acc, key) => {
              acc[key] = ""; // Set each step result's value to an empty string
              return acc;
            },
            {}
          );
          const answers = recreate
            ? {
                title: title,
                userContent: userContent,
                stepResults: resetStepResults,
                state: "created",
                finalScript: "",
              }
            : {
                title: title,
                userContent: userContent,
              };
          updateStatus(sectionStep.id, "GENERATING");
          setLessons((prevLessons) =>
            prevLessons.map((prev) =>
              prev.id === lesson?._id ? { ...prev, title: title } : prev
            )
          );
          updateLessonDetails(courseId, lesson?._id, answers)
            .then((res) => {
              setUpdated(false);

              if (!lesson.stepResults["RESULT"]) {
                generateScript("RESULT");
                setScriptListner(true);
                setSection("RESULT");
                setLesson((prev) => ({
                  ...prev,
                  title: title,
                  userContent: userContent,
                  state: "SCRIPT_GENERATION_IN_PROGRESS",
                }));

                updateStatus(sectionStep.id, "SCRIPT_GENERATION_IN_PROGRESS");
                setcurrentStatus("SCRIPT_GENERATION_IN_PROGRESS");
              } else {
                setLesson((prev) => ({
                  ...prev,
                  title: title,
                  stepResults: recreate ? resetStepResults : prev?.stepResults,
                  state: recreate ? "created" : prev?.state,
                  userContent: userContent,
                  finalScript: "",
                }));
                if (recreate) {
                  setcurrentStatus("created");
                  nextStepShown = false;
                  setIsDetailBoxOpen(false);
                  setSelectedScript();
                }
                updateStatus(sectionStep.id, sectionStep?.lessonState);
              }
            })
            .catch((err) => {});
        })
        .catch((errorInfo) => {
          console.log("Validation Failed:", errorInfo); // Handle validation errors
        });
    } else if (category === "lessonForm" && final) {
      updateStatus(sectionStep.id, "GENERATING");

      SubmitLessonScript(courseId, lesson?._id)
        .then((res) => {
          updateStatus(sectionStep.id, "LESSON_SCRIPT_COMPLETED");
          setChunks((prev) => [{ ...prev[0], text: res?.data?.finalScript }]);
          lessonForm.setFieldsValue([
            { text: res?.data?.finalScript, type: "normal", order: 0 },
          ]);
          setContentStep((prev) => prev + 1);
          setLesson((prev) => ({
            ...prev,
            finalScript: res?.data?.finalScript,
          }));
        })
        .catch((err) => {});
    }
    // }
  };

  const uploadFile = (formData) => {
    if (!lesson?.title) {
      NotificationInstance.info({ message: "Please enter a title" });
    } else {
      uploadPPT("Course", lesson?.title, formData, videoId, lesson?._id)
        .then((res) => {
          setloading(false);
          setVideoId(res?.data?.videoId);
          setSlidePreview(res?.data?.slidePreview);
          setPages(res?.data?.totalSlides);
          setIsModalOpen(false);
          NotificationInstance.success({
            message: "File uploaded successfully",
          });
        })
        .catch((err) => {
          setloading(false);
          NotificationInstance.error({ message: "File upload failed" });
        });
    }
  };

  const saveAnnotation = (type) => {
    setScriptUpdated(true);
    if (type === "slide" && !pages) {
      NotificationInstance.info({ message: "Please upload a Presentation" });
    } else {
      if (selectedText && selectedChunkIndex !== null) {
        const chunk = chunks[selectedChunkIndex];
        const newChunks = [];
        const startIndex = chunk.text.indexOf(selectedText);
        const endIndex = startIndex + selectedText.length;

        if (startIndex > 0) {
          const preText = chunk.text.substring(0, startIndex).trim();
          if (preText) {
            newChunks.push({
              text: preText,
              type: "normal",
              order: newChunks.length,
            });
          }
        }

        newChunks.push({
          text: selectedText,
          type: type,
          order: newChunks.length,
          subtitlesText: type === "subtitle" ? currentSubtitle : null,
          promptText: type === "video" ? currentPrompt : null,
          slideNumber: type === "slide" ? 1 : null,
        });

        if (endIndex < chunk.text.length) {
          const postText = chunk.text.substring(endIndex).trim();
          if (postText) {
            newChunks.push({
              text: postText,
              type: "normal",
              order: newChunks.length,
            });
          }
        }

        const updatedChunks = [
          ...chunks.slice(0, selectedChunkIndex),
          ...newChunks,
          ...chunks.slice(selectedChunkIndex + 1),
        ];

        // Update order for all chunks
        const orderedChunks = updatedChunks.map((c, index) => ({
          ...c,
          order: index,
        }));

        setChunks(orderedChunks);

        lessonForm.setFieldsValue(orderedChunks);
        setSelectedText("");
        setSelectedChunkIndex(null);

        if (type === "subtitle") {
          setCurrentSubtitle("");
        } else {
          setCurrentPrompt("");
        }
      }
    }
  };
  const findNextStep = (previousStep) => {
    const currentIndex = questions.findIndex(
      (step) => step.lessonStep === previousStep
    );
    if (currentIndex !== -1 && currentIndex < questions.length - 1) {
      return questions[currentIndex + 1];
    }
    return null;
  };

  const onUpdate = (step) => {
    const answers = {
      stepResults: lesson.stepResults,
    };
    // setIsDetailBoxOpen(false);

    updateLessonDetails(courseId, lesson?._id, answers)
      .then((res) => {
        const section = findNextStep(step);
        const sectionStep = section?.lessonStep ?? false;
        const hasResult = lesson?.stepResults[sectionStep];

        if (sectionStep && !hasResult) {
          setSelectedScript({
            step: section.lessonStep,
            question: section.message,
            script: lesson?.stepResults[section.lessonStep],
            section: section.lessonStep,
            status: "GENERATING",
          });
          setSection(sectionStep);
          generateScript(sectionStep);
        } else {
          setSelectedScript((prev) => ({
            ...prev,
            status: "COMPLETED",
          }));
        }
        // if (sectionStep === "STORY") {
        // setFinal(true);
        // }
        if (step === "STORY") {
          setIsDetailBoxOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const generateScript = (step, regenerating) => {
    generateLessonScript(courseId, lesson?._id, {
      section: step,
    })
      .then((res) => {
        if (regenerating) {
          regenerateRef.current = step;
          setRegenerating((prev) => [...prev, step]);
          setcurrentStatus("SCRIPT_GENERATION_IN_PROGRESS");
        }
        // setScriptListner(true);
      })
      .catch((err) => {});
  };

  const updateLesson = (data) => {
    lessonScriptForm.setFieldsValue({ ...lesson, userContent: data });
    NotificationInstance.success({
      message: "Successfully uploaded lesson content",
    });
  };

  const handleBack = (type) => {
    if (type === "lessonScript") {
      if (currentStep === "script") {
        setContentStep((prev) => prev - 1);
      } else {
        setcurrentStep("script");
      }
    }
  };

  const handleDiscard = () => {
    if (lesson?.scriptParts?.length > 0) {
      setChunks(lesson?.scriptParts);
      lessonForm.setFieldsValue(lesson?.scriptParts);
    } else {
      setChunks([{ text: lesson?.finalScript, type: "normal", order: 0 }]);
      lessonForm.setFieldsValue([
        { text: lesson?.finalScript, type: "normal", order: 0 },
      ]);
    }
    setScriptUpdated(false);
  };

  const updateLessonSteps = (data) => {
    if (data) {
      const stepsWithValues = Object.entries(data)
        .filter(([key, value]) => value !== "")
        .map(([key, value]) => key);
      if (regenerateRef.current) {
        const current = questions?.find(
          (step) => step.lessonStep === regenerateRef.current
        );
        if (current) {
          setSelectedScript({
            step: current.lessonStep,
            question: current.message,
            script: data[current.lessonStep],
            section: current.lessonStep,
            status: "COMPLETED",
          });
          setIsDetailBoxOpen(true);
        }
        regenerateRef.current = null;
      } else {
        if (stepsWithValues.length > 0) {
          const matchedStep = questions?.find(
            (step) =>
              step.lessonStep === stepsWithValues[stepsWithValues.length - 1]
          );

          if (stepsWithValues.length + 2 === questions.length) {
            setFinal(true);
            setSelectedScript({
              step: matchedStep.lessonStep,
              question: matchedStep.message,
              script: data[matchedStep.lessonStep],
              section: matchedStep.lessonStep,
              status: "COMPLETED",
            });
          } else {
            if (matchedStep) {
              setSelectedScript({
                step: matchedStep.lessonStep,
                question: matchedStep.message,
                script: data[matchedStep.lessonStep],
                section: matchedStep.lessonStep,
                status: "COMPLETED",
              });
              setIsDetailBoxOpen(true);
            }
          }
        } else {
          // setLastFilledStep(null); // Reset if no steps have values
        }
      }
    }
  };
  //firebase

  useEffect(() => {
    let unsubscribe;

    if (videoId) {
      const channelRef = query(
        ref(dataBase, `leads/videos/${videoId}`),
        endAt,
        limitToLast(1)
      );
      unsubscribe = onChildAdded(channelRef, (snapshot) => {
        const message = snapshot.val();
        if (message?.status === "completed") {
          setProgress(message);
          setcurrentStatus("VIDEO_GENERATION_COMPLETED");
          updateStatus(lesson?._id, "VIDEO_GENERATION_COMPLETED");
          setLesson((prev) => ({
            ...prev,
            state: "VIDEO_GENERATION_COMPLETED",
          }));
        } else {
          setProgress(message);
        }
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [videoId]);

  useEffect(() => {
    let unsubscribe;
    if (lesson?._id && currentStatus === "SCRIPT_GENERATION_IN_PROGRESS") {
      const channelRef = query(
        ref(dataBase, `lesson/${lesson?._id}`),
        endAt,
        limitToLast(1)
      );
      unsubscribe = onChildAdded(channelRef, (snapshot) => {
        const message = snapshot.val();
        if (
          message?.status === "LESSON_GENERATION_SUCCESS" ||
          message?.status === "LESSON_SCRIPT_COMPLETED"
        ) {
          setTimeout(() => {
            getLessonDetails(courseId, lesson?._id)
              .then((res) => {
                setScriptListner(false);
                setLesson(res?.data);
                updateLessonSteps(res?.data?.stepResults);
                setRegenerating([]);
              })
              .catch((err) => {});
          }, 5000);
        }
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [lesson?._id, currentStatus]);

  useEffect(() => {
    let unsubscribe;

    if (
      videoId &&
      (currentStatus === "AUDIO_GENERATION_INPROGRESS" ||
        currentStatus === "AUDIO_REGENERATION_INPROGRESS")
    ) {
      const channelRef = query(
        ref(dataBase, `audio/${videoId}`),
        endAt,
        limitToLast(1)
      );
      unsubscribe = onChildAdded(channelRef, (snapshot) => {
        const message = snapshot.val();

        if (message?.status === "AUDIO_GENERATED") {
          setTimeout(() => {
            getLessonAudios(courseId, lesson?._id)
              .then((res) => {
                setcurrentStatus("AUDIO_GENERATION_COMPLETED");
                setLesson((prev) => ({
                  ...prev,
                  state: "AUDIO_GENERATION_COMPLETED",
                }));
                const audioUrls = res?.data?.map((result) => result.url);
                setAudioUrlsByLesson(audioUrls);
              })
              .catch((err) => {});
          }, 5000);
        } else {
        }
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [videoId, lesson?._id, courseId, currentStatus]);

  //content updation

  useEffect(() => {
    if (lesson?.videoData) {
      setPages(lesson?.videoData?.totalSlides);
      setVideoId(lesson?.videoData?._id);
      setSlidePreview(lesson?.videoData?.slidePreview);
      setFile({ name: "" });
    }
    if (lesson?.scriptParts?.length > 0) {
      const lessonParts = JSON.parse(JSON.stringify(lesson?.scriptParts));
      setChunks(lessonParts);
      lessonForm.setFieldsValue(lessonParts);
    } else if (lesson?.finalScript) {
      setChunks((prev) => [{ ...prev[0], text: lesson?.finalScript }]);
      lessonForm.setFieldsValue([
        { text: lesson?.finalScript, type: "normal", order: 0 },
      ]);
    }
    if (lesson?.audioData?.length > 0) {
      const audioUrls = lesson?.audioData?.map((result) => result.url);
      setAudioUrlsByLesson(audioUrls);
    }
  }, [lesson?.videoData, lesson?.audioData]);

  // useEffect(() => {
  //   if (
  //     lesson?.finalScript &&
  //     lesson?.audioData?.length === 0 &&
  //     !finalScriptUpdated
  //   ) {
  //     setChunks([{ text: lesson?.finalScript, type: "normal", order: 0 }]);
  //     lessonForm.setFieldsValue([
  //       { text: lesson?.finalScript, type: "normal", order: 0 },
  //     ]);
  //     setFinalScriptUpdated(true);
  //   }
  // }, [lesson?.finalScript, lesson?.audioData, finalScriptUpdated]);

  useEffect(() => {
    if (courseId && lessonId && questions) {
      setLessonLoading(true);
      getLessonDetails(courseId, lessonId)
        .then((res) => {
          setLessonLoading(false);
          setLesson(res?.data);
          updateLessonSteps(res?.data?.stepResults);
          setcurrentStatus(res?.data?.state);
        })
        .catch((err) => {
          setLessonLoading(false);
        });
    }
  }, [courseId, lessonId, questions]);

  const handleDiscardForm = () => {
    setUpdated();
    lessonScriptForm.setFieldsValue({ ...lesson });
  };

  const renderButton = (type) => {
    if (type === "lessonForm") {
      if (
        lesson?.state !== "created" &&
        lesson?.state !== "SCRIPT_GENERATION_IN_PROGRESS" &&
        !updated &&
        lesson?.finalScript
      ) {
        return (
          <Button
            type="primary"
            onClick={() => setContentStep((prev) => prev + 1)}
          >
            Next
          </Button>
        );
      } else if (lesson?.state === "created" || updated) {
        const recreate = updated && updated?.includes("userContent");
        return lesson?.state !== "created" && recreate ? (
          <Flex gap={20}>
            <Button onClick={() => handleDiscardForm()}>Discard Changes</Button>
            <Popconfirm
              overlayStyle={{ width: 400 }}
              title="Lesson Briefing"
              description="Updating the lesson content will remove all the steps created from this lesson. If you proceed, all dependent steps will be deleted. Do you want to continue?"
              onConfirm={() => onSubmit(type, step.type, recreate)}
            >
              <Button
                type="primary"
                loading={sectionStep.status === "GENERATING"}
              >
                {sectionStep.status === "GENERATING"
                  ? "Updating Lesson"
                  : "Update Lesson"}
              </Button>
            </Popconfirm>
          </Flex>
        ) : (
          <Flex gap={20}>
            {updated && currentStatus !== "created" && (
              <Button onClick={() => handleDiscardForm()}>
                Discard Changes
              </Button>
            )}
            <Button
              type="primary"
              loading={sectionStep.status === "GENERATING"}
              onClick={() => onSubmit(type, step.type)}
            >
              {lesson?.state === "created"
                ? sectionStep.status === "GENERATING"
                  ? "Generating Script Section"
                  : "Generate Script Section"
                : sectionStep.status === "GENERATING"
                ? "Updating Lesson"
                : "Update Lesson"}
            </Button>
          </Flex>
        );
      } else if (lesson?.state === "LESSON_SCRIPT_COMPLETED") {
        return (
          <Button
            type="primary"
            loading={sectionStep.status === "GENERATING"}
            onClick={() => onSubmit(type, step.type)}
          >
            {sectionStep.status === "GENERATING"
              ? "Generating Script"
              : "Confirm Submit"}
          </Button>
        );
      }
    } else if (type === "lessonScript") {
      if (
        scriptStages.includes(lesson?.state) &&
        ((currentStatus !== "AUDIO_GENERATION_INPROGRESS" &&
          currentStatus !== "AUDIO_REGENERATION_INPROGRESS") ||
          currentStep === "script") &&
        !scriptUpdated
      ) {
        return (
          <Button
            type="primary"
            onClick={() => {
              currentStep === "script"
                ? setcurrentStep("audio")
                : setContentStep((prev) => prev + 1);
              setIsDetailBoxOpen(false);
            }}
          >
            Next
          </Button>
        );
      } else if (
        (lesson?.state === "LESSON_SCRIPT_COMPLETED" &&
          currentStep === "script") ||
        scriptUpdated
      ) {
        return scriptUpdated && scriptUpdateStages.includes(currentStatus) ? (
          <Popconfirm
            overlayStyle={{ width: 400 }}
            title="Lesson Script"
            description="Updating the lesson content will delete all the audios generated previously. Do you want to continue?"
            onConfirm={() => {
              onSubmit(type, step.type);
            }}
          >
            <Button type="primary" loading={confirmScript}>
              {confirmScript ? "Generating Audios" : "Confirm Script"}
            </Button>
          </Popconfirm>
        ) : (
          <Button
            type="primary"
            loading={confirmScript}
            onClick={() => onSubmit(type, step.type)}
          >
            {confirmScript
              ? "Generating Audios"
              : "Confirm Script"}
          </Button>
        );
      }
    }
  };

  const renderContent = (type) => {
    if (type === "lessonForm") {
      return lesson ? (
        <Flex vertical gap={16} flex={1} style={{ height: "fit-content" }}>
          <Form
            onFieldsChange={(e) => {
              if (e.length > 0) {
                setUpdated((prev) => {
                  const fieldName = e[0]?.name?.[0];
                  if (prev && prev?.includes(fieldName)) {
                    return prev;
                  }
                  return prev ? [...prev, fieldName] : [fieldName];
                });
              }
              setFinal(false);
            }}
            form={lessonScriptForm}
            layout="vertical"
            className="course-detail-form"
            initialValues={sectionStep.id === lesson?._id ? lesson : ""}
          >
            {questions
              ?.filter((item, index) => index < 2)
              .map((item, index) => (
                <Form.Item
                  required={false}
                  className={`${index === 0 ? "title" : "userContent"}`}
                  label={
                    <span className="question">
                      <Flex vertical flex={1} gap={10}>
                        <span className="lesson-title">
                          Lesson {index === 0 ? "Title" : "Summary"}
                        </span>
                        {index === 0 ? (
                          <div>
                            {item.message.split("LOREM IPSUM")[0]}
                            <i>{lesson?.title || "LOREM IPSUM"}</i>
                            {item.message.split("LOREM IPSUM")[1]}
                          </div>
                        ) : (
                          item.message
                        )}
                      </Flex>
                    </span>
                  }
                  name={`${index === 0 ? "title" : "userContent"}`}
                  rules={[
                    {
                      required: true,
                      message: `Please enter ${
                        index === 0 ? "title" : "content"
                      }`,
                    },
                  ]}
                >
                  {index === 0 ? (
                    <Input
                      readOnly={scriptUpdateStages.includes(currentStatus)}
                      className="answer-input"
                      placeholder="Enter your answer here"
                      value={lesson?.title}
                    />
                  ) : (
                    <TextArea
                      readOnly={scriptUpdateStages.includes(currentStatus)}
                      spellCheck={false}
                      autoSize={{ minRows: 5, maxRows: 6 }}
                      placeholder="Enter your answer here"
                      value={lesson?.userContent}
                    />
                  )}
                </Form.Item>
              ))}
          </Form>

          {!scriptUpdateStages.includes(currentStatus) && (
            <>
              <Divider style={{ margin: "0" }}>OR</Divider>
              <LessonDocument
                courseId={courseId}
                lessonId={sectionStep?.id}
                updateLesson={updateLesson}
              />
            </>
          )}
          <Flex vertical gap={16} ref={stepContainer}>
            {lesson.userContent &&
              questions
                ?.filter((item, index) => {
                  const hasStepResults =
                    lesson?.stepResults && lesson?.stepResults[item.lessonStep];
                  if (currentStatus === "created") {
                    return false;
                  }
                  if (hasStepResults && lesson?.stepResults[item.lessonStep]) {
                    return true;
                  }
                  if (
                    !nextStepShown &&
                    index >= 2 &&
                    !lesson?.stepResults[item.lessonStep]
                  ) {
                    nextStepShown = true;
                    return true;
                  }

                  return false;
                })
                .map((item) => (
                  <Flex
                    vertical
                    gap={20}
                    className="script-result-container"
                    key={item._id}
                  >
                    <span>{item.message}</span>
                    <Flex
                      gap={10}
                      justify="space-between"
                      align="center"
                      className="script-result"
                    >
                      {lesson?.stepResults &&
                        (lesson?.stepResults[item.lessonStep] ||
                          item.lessonStep === section) && (
                          <>
                            <span>{item.lessonStep}</span>
                            <Button
                              type={
                                lesson?.stepResults[item.lessonStep]
                                  ? "default"
                                  : "primary"
                              }
                              onClick={() => {
                                setSelectedScript({
                                  step: item.lessonStep,
                                  question: item.message,
                                  script: lesson?.stepResults[item.lessonStep],
                                  section: item.lessonStep,
                                  status: regenerating.includes(item.lessonStep)
                                    ? "REGENERATING"
                                    : "COMPLETED",
                                });
                                setIsDetailBoxOpen(true);
                              }}
                              loading={!lesson?.stepResults[item.lessonStep]}
                            >
                              {lesson?.stepResults[item.lessonStep]
                                ? "View script"
                                : "Generating Script"}
                            </Button>
                          </>
                        )}
                    </Flex>
                  </Flex>
                ))}
          </Flex>
        </Flex>
      ) : (
        <div></div>
      );
    } else if (type === "lessonScript") {
      return currentStep === "script" ? (
        <LessonScriptUpdation
          script={lesson?.finalScript}
          chunks={chunks}
          setChunks={setChunks}
          currentPrompt={currentPrompt}
          currentSubtitle={currentSubtitle}
          setCurrentPrompt={setCurrentPrompt}
          setCurrentSubtitle={setCurrentSubtitle}
          setSelectedText={setSelectedText}
          setSelectedChunkIndex={setSelectedChunkIndex}
          lessonForm={lessonForm}
          pages={pages}
          currentStatus={currentStatus}
          setScriptUpdated={setScriptUpdated}
          setAudioUrlsByLesson={setAudioUrlsByLesson}
          slidePreview={slidePreview}
        />
      ) : (
        <LessonAudio
          chunks={chunks}
          setChunks={setChunks}
          courseId={courseId}
          lessonId={lesson?._id}
          setVideoId={setVideoId}
          videoId={videoId}
          audioUrlsByLesson={audioUrlsByLesson}
          setAudioUrlsByLesson={setAudioUrlsByLesson}
          setcurrentStatus={setcurrentStatus}
          currentStatus={currentStatus}
          addVideoId={addVideoId}
        />
      );
    } else if (type === "lessonVideo") {
      return (
        <LessonVideo
          videoId={videoId}
          status={progress}
          lesson={lesson}
          lessons={lessons}
          setLesson={setLesson}
          setsectionStep={setsectionStep}
          updateStep={updateStep}
          setcurrentStatus={setcurrentStatus}
          handleChangeSectionStep={handleChangeSectionStep}
        />
      );
    }
  };
  const container = useRef();
  const stepContainer = useRef();

  useEffect(() => {
    const containerDiv = container?.current;

    // Wait for stepContainer to be available
    const observerForContainer = new MutationObserver(() => {
      const stepContainerDiv = stepContainer?.current;

      if (containerDiv && step.type === "lessonForm" && stepContainerDiv) {
        const handleScrollToBottom = () => {
          containerDiv.scroll({
            top: containerDiv.scrollHeight,
            behavior: "smooth",
          });
        };

        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.addedNodes.length > 0) {
              handleScrollToBottom();
            }
          });
        });

        observer.observe(stepContainerDiv, {
          childList: true,
          subtree: true,
        });

        // Clean up the observer
        return () => {
          observer.disconnect();
        };
      }
    });

    // Observe the container until stepContainer is rendered
    observerForContainer.observe(containerDiv, {
      childList: true,
      subtree: true,
    });

    // Cleanup the observer when component unmounts
    return () => {
      observerForContainer.disconnect();
    };
  }, [container, step, stepContainer]);

  return (
    <>
      <Row className="course-detail">
        <Col
          span={
            type === "lesson" &&
            step.type === "lessonScript" &&
            !videoStages.includes(currentStatus ?? "")
              ? currentStep === "script"
                ? 16
                : 19
              : isDetailBoxOpen
              ? 16
              : 24
          }
          style={{
            height: "100%",
          }}
        >
          {isModalOpen && (
            <UploadVideoPresentation
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              handleCancel={() => setIsModalOpen(false)}
              handleOk={uploadFile}
              setFile={setFile}
              file={file}
              setloading={setloading}
              loading={loading}
            />
          )}
          <Flex vertical className="course-detail-container">
            <Flex className="course-detail-header" justify="space-between">
              <span className="course-header-title">{step.title}</span>
            </Flex>
            {!lessonLoading ? (
              <Flex flex={1} className="course-content" ref={container}>
                {renderContent(step.type)}
              </Flex>
            ) : (
              <Flex flex={1} justify="center" align="center" vertical>
                <h3>Loading Lesson Details...</h3>
                <p>Please wait while we fetch the lesson information.</p>

                <div
                  className="video-loader"
                  style={{ width: "100%", marginTop: "20px" }}
                >
                  <div className="loader"></div>
                </div>
              </Flex>
            )}
            <Flex className="course-detail-footer" justify="flex-end">
              {step.type === "script" || step.type === "lessonScript" ? (
                <Flex flex={1} justify="space-between">
                  {((currentStep === "audio" &&
                    currentStatus !== "AUDIO_GENERATION_INPROGRESS" &&
                    currentStatus !== "AUDIO_REGENERATION_INPROGRESS") ||
                    currentStep === "script") && (
                    <Button onClick={() => handleBack(step?.type)}>Back</Button>
                  )}
                  {scriptUpdated &&
                    currentStep === "script" &&
                    step.type === "lessonScript" && (
                      <Button onClick={() => handleDiscard()}>
                        Discard Changes
                      </Button>
                    )}
                  {((currentStep === "audio" &&
                    currentStatus !== "AUDIO_GENERATION_INPROGRESS" &&
                    currentStatus !== "AUDIO_REGENERATION_INPROGRESS") ||
                    currentStep === "script") &&
                    renderButton(step.type)}
                </Flex>
              ) : (
                renderButton(step.type)
              )}
            </Flex>
          </Flex>
        </Col>
        {type === "lesson" &&
          step.type === "lessonScript" &&
          currentStep === "script" &&
          !videoStages.includes(currentStatus ?? "") && (
            <Col
              span={8}
              style={{
                height: "100%",
              }}
            >
              <div className="course-steps">
                <Flex vertical className="course-steps-container">
                  {items.map((item) => (
                    <Tooltip
                      title={
                        !selectedText
                          ? item.key === "upload"
                            ? ""
                            : item.key === "slide" && !pages
                            ? "Please upload a slide"
                            : "Please select at least one sentence"
                          : ""
                      }
                    >
                      <Flex
                        gap={10}
                        className="script-option"
                        align="center"
                        onClick={() =>
                          item.key === "upload"
                            ? setIsModalOpen(true)
                            : saveAnnotation(item.key)
                        }
                      >
                        {item.icon}
                        <span>{item.label}</span>
                      </Flex>
                    </Tooltip>
                  ))}
                </Flex>
              </div>
            </Col>
          )}
        {type === "lesson" && step.type === "lessonForm" && isDetailBoxOpen && (
          <Col
            span={8}
            style={{
              height: "100%",
            }}
          >
            <LessonScript
              selectedScript={selectedScript}
              setLesson={setLesson}
              setSelectedScript={setSelectedScript}
              onUpdate={onUpdate}
              setSection={setSection}
              generateScript={generateScript}
              currentStatus={currentStatus}
              steps={lesson?.stepResults}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default LessonDetails;
