import React, { useEffect, useState } from "react";
import "./videoPreview.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button, Flex } from "antd";
import { getVideoUrl } from "../../api/hubSpotService";
import { diy } from "../../components/generateDIYVideo/diy";
import Logo from "../../assets/images/leaplerning.png";

const VideoPreview = () => {
  const [loading, setLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("referenceToken");
  const params = useParams();
  const { videoId } = params;
  const language = searchParams.get("language");
  const [lang, setLang] = useState(language === "de" ? "de" : "en");
  const navigate = useNavigate();

  const type = "DIY_DEMO";
  useEffect(() => {
    if (videoId) {
      setLoading(true);
      getVideoUrl(type, videoId, token)
        .then((res) => {
          console.log(res);

          if (res?.data) {
            setVideoUrl(res?.data?.url);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [videoId]);

  return (
    <Flex justify="center" align="center" vertical className="diy-preview">
      <div className="diy-bg" />
      <Flex align="left" className="diy-logo-container diy-logo">
        <img src={Logo} alt="" className="leap-learning" />
      </Flex>
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : videoUrl ? (
        <div className="diy-video-container">
          <h1> {diy[lang].videoPreview.title}</h1>
          {diy[lang].videoPreview.detail?.map((item) => (
            <p>{item}</p>
          ))}
          <div className="video-player-container">
            <iframe
              title="video-player"
              src={videoUrl}
              loading="lazy"
              style={{
                border: "none",
                position: "absolute",
                top: 0,
                right: 0,
                height: "100%",
                width: "100%",
              }}
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowFullScreen
              onError={(e) => console.error("Error loading video:", e)}
            ></iframe>
          </div>
          <div style={{ textAlign: "left" }}>
            {diy[lang].videoPreview.description?.map((item) => (
              <p>{item}</p>
            ))}
          </div>
          <Flex vertical justify="center" align="center" gap={20}>
            <a
              href={diy[lang].videoPreview.link}
              target="_blank"
              className="consultation"
            >
              {" "}
              {diy[lang].videoPreview.button}
            </a>
            <a href={diy[lang].videoPreview.link} target="_blank">
              {" "}
              {diy[lang].videoPreview.linkPreview}
            </a>
          </Flex>
        </div>
      ) : (
        <div className="diy-video-container">
          <h1>Video Url missing</h1>
        </div>
      )}
    </Flex>
  );
};

export default VideoPreview;
