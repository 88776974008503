import React, { useEffect } from "react";
import { ConfigProvider, Tabs } from "antd";
import { PRIMARY_COLOR } from "../../utils/colors";
import VideoLibraryTable from "../../components/videoListTable/videoLibraryTable";
import { useOutletContext } from "react-router-dom";

const VideoLibrary = () => {
  const [, , setDisplayName] = useOutletContext();
  useEffect(() => {
    setDisplayName(["Library"]);
  }, []);

  const items = [
    {
      key: "1",
      label: "Personalized",
      children: <VideoLibraryTable type={"Personalized"} />,
    },
    {
      key: "2",
      label: "Training",
      children: <VideoLibraryTable type={"Training"} />,
    },
    {
      key: "3",
      label: "Journey",
      children: <VideoLibraryTable type={"Journey"} />,
    },
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            colorBorder: PRIMARY_COLOR,
            inkBarColor: PRIMARY_COLOR,
            itemColor: PRIMARY_COLOR,
          },
        },
      }}
    >
      <Tabs defaultActiveKey="1" items={items} />
    </ConfigProvider>
  );
};

export default VideoLibrary;
