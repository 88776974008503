import { Button, Flex, Input, Modal, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import "./modal.css";
import { getVideoUrl } from "../../api/hubSpotService";
import { CopyOutlined } from "@ant-design/icons";

const VideoPlayer = ({ data, isOpen, setIsOpen, type }) => {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");

  const handleCopy = () => {
    navigator.clipboard.writeText(url?.url);
  };
  useEffect(() => {
    if (data?._id) {
      setLoading(true);
      getVideoUrl(type, data?._id)
        .then((res) => {
          if (res?.data) {
            setUrl(res?.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [data?._id, type]);

  return (
    <Modal
      open={isOpen}
      footer={false}
      width={700}
      maskClosable={true}
      className="content-display"
      onCancel={() => {
        setIsOpen(false);
      }}
    >
      {loading ? (
        <Flex justify="center" align="center" className="video-loader">
          <div className="loader"></div>
        </Flex>
      ) : url?.url ? (
        <>
          <iframe src={url?.url} frameborder="0"></iframe>
          <Flex gap={20}>
            <Input value={url?.url} readOnly />
            <Tooltip title="Copied" trigger="click" placement="bottom">
              <Button onClick={handleCopy}>
                <CopyOutlined />
              </Button>
            </Tooltip>
          </Flex>
        </>
      ) : (
        <Flex justify="center" align="center">
          <h2>No URL link available</h2>
        </Flex>
      )}
    </Modal>
  );
};

export default VideoPlayer;
