import React, { useEffect, useState } from "react";
import "./courseList.css";
import { Button, Empty, Flex, Table, Tag } from "antd";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { getVideoList } from "../../api/hubSpotService";
import { timeCreatedOn } from "../../utils/format";
import VideoPlayer from "../../components/modals/videoPlayer";
import { getAllCourses } from "../../api/adminService";
import CustomPagination from "../../components/customPagination/customPagination";
import CustomTable from "../../components/customTable/customTable";

const CourseVideoList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selecteddata, setSelectedData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [, , setDisplayName] = useOutletContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);

  const [total, setTotal] = useState();
  useEffect(() => {
    setDisplayName(["Courses"]);
  }, []);
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <Link to={`/courses/${record?._id}`}>
          <span className="course-title">{text}</span>
        </Link>
      ),
      width: "60%",
    },
    {
      title: "Created By",
      dataIndex: "createdByStaff",
      key: "createdBy",
      render: (text) => <p>{`${text?.firstName} ${text?.lastName}`}</p>,
      width: "20%",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <p>{timeCreatedOn(text)}</p>,
      width: "20%",
    },
  ];

  const fetchCourseList = async () => {
    setIsLoading(true);
    console.log("here1");

    getAllCourses(currentPage, pageSize)
      .then((res) => {
        setData(res?.data);
        setIsLoading(false);
        setTotal(res?.info?.totalCount);
        console.log("here", res);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleAddVideo = () => {
    navigate("/courses/create");
  };

  useEffect(() => {
    fetchCourseList();
  }, [currentPage]);

  return (
    <>
      <Flex justify="space-between" align="center" gap={20}>
        <div className="journey-title">
          <h1>All Courses</h1>
        </div>

        <Button
          className="add-training-video"
          type="primary"
          onClick={handleAddVideo}
        >
          Create New Course
        </Button>
      </Flex>
      <CustomTable
        data={data}
        loading={isLoading}
        columns={columns}
        total={total}
        pageSize={pageSize}
        item={"Course"}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </>
  );
};

export default CourseVideoList;
