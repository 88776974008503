import NotificationInstance from "../services/notificationServices";
import { AxiosInstance1 } from "./axiosInstance";
import axios from "axios";
let source;

export const getContacts = async () => {
  try {
    const res = await AxiosInstance1.get("leads");
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getContactById = async (id) => {
  try {
    const res = await AxiosInstance1.get(`leads/${id}`);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const syncData = async () => {
  try {
    await AxiosInstance1.post("leads/sync/data");
  } catch (error) {
    console.log(error);
  }
};

export const getVideoList = async (type) => {
  try {
    const res = await AxiosInstance1.get(`videos?videoType=${type}`);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const generateAudio = async (body) => {
  const CancelToken = axios.CancelToken;
  source = CancelToken.source();
  try {
    const res = await AxiosInstance1.post(`leads/generate-audio`, body);
    const { data, info } = res.data;
    return { data, info };
  } catch (error) {
    console.log(error);
    NotificationInstance.error({
      message: "Error generating audio",
      description: error?.response?.data?.error,
    });
  }
};

export const generateAudioBatch = async (body) => {
  const CancelToken = axios.CancelToken;
  source = CancelToken.source();
  try {
    const res = await AxiosInstance1.post(`leads/generate-audio-batch`, body);
    const { data, info } = res.data;
    return { data, info };
  } catch (error) {
    console.log(error);
    NotificationInstance.error({
      message: "Error generating audio",
      description: error?.response?.data?.error,
    });
  }
};

export const generateVideo = async (body) => {
  try {
    const res = await AxiosInstance1.post("leads/generate/video-batch", body);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getVideoStatus = async (videoId) => {
  try {
    const res = await AxiosInstance1.get(
      `leads/video/status?videoId=${videoId}`
    );
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchSuggestedScript = async (leadId, script) => {
  try {
    const res = await AxiosInstance1.post("leads/script/suggestions", {
      leadId,
      script,
    });
    return res.data.data.scriptSuggestions;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAvatars = async () => {
  try {
    const res = await AxiosInstance1.get("avatars?syncData=true");
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const getVoiceClones = async () => {
  try {
    const res = await AxiosInstance1.get("voices?syncData=false");
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const getVideoUrl = async (type, id, token) => {
  const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};

  let query = `?id=${id}`;

  try {
    const response = await AxiosInstance1.get(`/videos/url` + query, config);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("video url fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const deleteAudios = async (id) => {
  try {
    const response = await AxiosInstance1.delete(`/leads/video/${id}`);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("audio deletion failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getScriptQuestions = async (token) => {
  const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
  try {
    const response = await AxiosInstance1.get(`/scripts/questions`, config);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("script fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const submitScriptResult = async (data) => {
  try {
    const response = await AxiosInstance1.post(`/scripts`, data);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("submission failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getScripts = async (id) => {
  try {
    const response = await AxiosInstance1.get(`/scripts/result/${id}`);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("script fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const submitDemoScriptResult = async (data, demoId, token, lang) => {
  const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
  try {
    const response = await AxiosInstance1.post(
      `/demo/submit/${lang}?demoId=${demoId}`,
      data,
      config
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("Submission failed");
    }
  } catch (error) {
    throw error;
  }
};
export const verifyDemoToken = async (id, token) => {
  try {
    const response = await AxiosInstance1.get(`/demo/verify?demoId=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("token verification failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getDemoUrl = async () => {
  try {
    const response = await AxiosInstance1.get(`/demo/generate-url`);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("url fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const uploadPPT = async (type, title, data, videoId, lessonId) => {
  let query = "?";
  if (type) {
    query = query + `videoType=${type}&`;
  }
  if (title) {
    query = query + `title=${title}&`;
  }
  if (videoId) {
    query = query + `videoId=${videoId}&`;
  }
  if (lessonId) {
    query = query + `lessonId=${lessonId}`;
  }
  try {
    const response = await AxiosInstance1.post(
      `/leads/video/upload/ppt` + query,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("thumbnail upload failed");
    }
  } catch (error) {
    throw error;
  }
};
