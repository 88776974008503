import React, { useEffect, useState } from "react";
import { Button, Flex, message, Skeleton, Steps } from "antd";
import CreateVideo from "../../generateVideo/createvideo/createVideo";
import ScriptVerification from "../../generateVideo/scriptVerification/ScriptVerification";
import AudioVerification from "../../generateVideo/audioVerification/audioVerification";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import {
  getContactById,
  getScriptQuestions,
} from "../../../api/hubSpotService";
import VideoSidebar from "../../sidebar/videoSidebar";
import ScriptCreation from "../scriptCreation/scriptCreation";
import TrainingScriptVerification from "../trainingScriptVerification/trainingScriptVerification";
import TrainingAudioVerification from "../trainingAudioVerification/trainingAudioVerification";

const GenerateTrainingSteps = () => {
  const { hubspotId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [title, setTitle] = useState("");
  const [script, setScript] = useState("");
  const [current, setCurrent] = useState(0);
  const [videoId, setVideoId] = useState("");
  const [isAllAudio, setIsAllAudio] = useState(false);
  const [avatarId, setAvatarId] = useState("");
  const [voiceId, setVoiceId] = useState("");
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentScript, setcurrentScript] = useState("");
  const [changed, setchanged] = useState(false);
  const [voiceChanged, setVoiceChanged] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [, , setDisplayName] = useOutletContext();
  const [disableNext, setDisableNext] = useState(false);
  const [finalChunks, setfinalChunks] = useState([]);
  const [videoStatus, setVideoStatus] = useState();
  const [chunks, setChunks] = useState([{ text: script, type: "normal",order:0 }]);
  const [pages, setPages] = useState(0);
  const [file, setFile] = useState();

  useEffect(() => {
    setDisplayName(["Training", "Create"]);
  }, []);

  const next = () => {
    if (current === 0) {
      const wordCount = script.trim().split(/\s+/).length;
      // if (wordCount < 6) {
      //   message.info("Please enter minimum 6 words");
      // } else {
      if (script !== currentScript) {
        setcurrentScript(script);
        setchanged(true);
        setUpdated(true);
      }
      setCurrent(current + 1);
      // }
    } else {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const handleNavigation = () => {
    navigate("/training-video");
  };
  const steps = [
    {
      title: "Script Verification",
      content: (
        <TrainingScriptVerification
          script={script}
          setScript={setScript}
          setfinalChunks={setfinalChunks}
          next={next}
          chunks={chunks}
          setChunks={setChunks}
          title={title}
          setTitle={setTitle}
          videoId={videoId}
          setVideoId={setVideoId}
          file={file}
          setFile={setFile}
          pages={pages}
          setPages={setPages}
        />
      ),
    },
    {
      title: "Audio Verification",
      content: (
        <TrainingAudioVerification
          script={script}
          chunks={finalChunks}
          setChunks={setfinalChunks}
          videoId={videoId}
          setVideoId={setVideoId}
          videoType={"Training"}
          leadId={hubspotId}
          setIsAllAudio={setIsAllAudio}
          voiceId={voiceId}
          setUpdated={setUpdated}
          changed={changed}
          setchanged={setchanged}
          voiceChanged={voiceChanged}
          setVoiceChanged={setVoiceChanged}
          setDisableNext={setDisableNext}
          title={title}
        />
      ),
    },
    {
      title: "Generate Video",
      content: (
        <CreateVideo
          script={script}
          setScript={setScript}
          videoId={videoId}
          title={title}
          avatarId={avatarId}
          updated={updated}
          setUpdated={setUpdated}
          setGenerating={setGenerating}
          setVideoStatus={setVideoStatus}

        />
      ),
    },
  ].filter(Boolean);

  const items = steps.map((item, index) => ({
    key: item.title,
    title: item.title,
  }));

  const handleNextDisable = () => {
    if (current === 0 && !script) return true;
    if (current === 1 && !isAllAudio) return true;
    return false;
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await getContactById(hubspotId);
        setUser(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    if (hubspotId) {
      setLoading(true);
      fetchUser();
    }
  }, [hubspotId]);

  return (
    <Flex gap={50} style={{ height: "calc(100vh - 120px)" }}>
      <VideoSidebar
        voiceId={voiceId}
        avatarId={avatarId}
        setVoiceId={setVoiceId}
        setAvatarId={setAvatarId}
        setVoiceChanged={setVoiceChanged}
        current={current}
      />
      <Flex flex={1} vertical>
        {loading ? (
          <Skeleton
            paragraph={{
              rows: 2,
            }}
          />
        ) : (
          user?.mbtiProfile?.communicationStyle && (
            <Flex
              className="conatct-details"
              vertical
              gap={0}
              align="flex-start"
            >
              <h1 style={{ margin: 0 }}>
                {user?.firstname} {user?.lastname}
              </h1>
              {user?.mbtiProfile?.communicationStyle && (
                <div className="communication-style">
                  <h3>Communication Style</h3>
                  <span>{user?.mbtiProfile?.communicationStyle}</span>
                </div>
              )}
            </Flex>
          )
        )}
        <Steps
          current={current}
          items={steps.map((step) => ({ title: step?.title }))}
        />
        <>{steps[current]?.content}</>
        <div
          style={{
            textAlign: "end",
            marginTop: "20px",
          }}
        >
          {current > 0 && !videoStatus && (
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => prev()}
              disabled={generating || disableNext}
            >
              Previous
            </Button>
          )}
          {current < steps.length - 1 && current !== 0 && current > 0 && (
            <Button
              type="default"
              onClick={() => next()}
              disabled={handleNextDisable() || disableNext}
            >
              Proceed
            </Button>
          )}

          {current === steps.length - 1 && (
            <Button
              type="default"
              onClick={() => {
                handleNavigation();
              }}
              disabled={videoStatus!=="completed"}
            >
              Done
            </Button>
          )}
        </div>
      </Flex>
    </Flex>
  );
};
export default GenerateTrainingSteps;
