import React, { useState, useRef, useEffect } from "react";
import { Button, Input, Flex, Divider, Select, Form, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import { videoStages } from "../../../utils/format";

const LessonScriptUpdation = ({
  script,
  chunks,
  setChunks,
  title,
  pages,
  setSelectedChunkIndex,
  setSelectedText,
  lessonForm,
  currentStatus,
  setScriptUpdated,
  setAudioUrlsByLesson,
  slidePreview,
}) => {
  
  const textAreaRefs = useRef([]);

  const handleTextSelection = (e, chunkIndex) => {
    const textarea = e.target;
    const selectedText = textarea.value.substring(
      textarea.selectionStart,
      textarea.selectionEnd
    );
    const wordCount = selectedText.trim().split(/\s+/).length;

    if (selectedText.length > 0 && wordCount >= 6) {
      setSelectedText(selectedText);
      setSelectedChunkIndex(chunkIndex);
    } else {
      setSelectedText("");
      setSelectedChunkIndex(null);
    }
  };

  const handleRemoveAnnotation = (index) => {
    const chunk = chunks[index];
    const updatedChunks = [
      ...chunks.slice(0, index),
      { text: chunk.text, type: "normal", order: index },
      ...chunks.slice(index + 1),
    ];

    // Update order and merge adjacent normal chunks
    // const mergedChunks = [];
    // updatedChunks.forEach((c, idx) => {
    //   if (
    //     mergedChunks.length > 0 &&
    //     c.type === "normal" &&
    //     mergedChunks[mergedChunks.length - 1].type === "normal"
    //   ) {
    //     mergedChunks[mergedChunks.length - 1].text += ` ${c.text}`;
    //   } else {
    //     mergedChunks.push({ ...c, order: mergedChunks.length });
    //   }
    // });

    setChunks(updatedChunks);
    // form.setFieldsValue(mergedChunks);
    lessonForm.setFieldsValue({ title: title, ...updatedChunks });

    setSelectedText("");
    setSelectedChunkIndex(null);
  };

  const generateOptions = (num) => {
    return Array.from({ length: num }, (_, i) => ({
      label: i + 1,
      value: i + 1,
    }));
  };

  return (
    <div style={{ width: "100%" }}>
      <Form
        form={lessonForm}
        initialValues={chunks}
        onFieldsChange={() => {
          setScriptUpdated(true);
        }}
      >
        <Flex vertical gap={10} flex={1}>
          {chunks?.map((chunk, index) => (
            <div
              key={chunk?.order}
              style={{
                display: "flex",
                flexDirection: "column",
                background: `${chunk?.type === "normal" ? "none" : "#eee"}`,
                padding: `${chunk?.type === "normal" ? "0" : "10px"}`,
                borderRadius: 8,
              }}
            >
              <Form.Item
                style={{ width: "100%" }}
                required={false}
                label={""}
                name={[index, "text"]} // This binds the index and 'text' field
                rules={[
                  {
                    required: true,
                    message: "Please enter the script",
                  },
                  {
                    validator: (_, value) => {
                      if (value) {
                        const wordCount = value
                          .split(" ")
                          .filter((word) => word).length;
                        if (wordCount >= 6) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error(
                              "The script must be at least one full sentence or 6 words"
                            )
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <TextArea
                  spellCheck={false}
                  className="answer-input"
                  readOnly={videoStages.includes(currentStatus)}
                  ref={(el) => (textAreaRefs.current[index] = el)}
                  value={chunk.text || script}
                  rows={chunks?.length == 1 ? 20 : 6}
                  placeholder="Enter your script here"
                  onMouseUp={(e) => handleTextSelection(e, index)}
                  onKeyUp={(e) => handleTextSelection(e, index)}
                  onChange={(e) => {
                    // Update the chunk's text as the user types
                    const updatedChunks = [...chunks];
                    updatedChunks[index].text = e.target.value;
                    setChunks(updatedChunks);
                  }}
                />
              </Form.Item>
              {/* Subtitle or Video Input Handling */}
              {(chunk.type === "subtitle" || chunk.type === "video") && (
                <Flex vertical gap={10} flex={1}>
                  <Flex vertical gap={1} align="flex-start" flex={1}>
                    <span>{chunk.type}</span>
                    <Form.Item
                      style={{ width: "100%" }}
                      required={false}
                      label={""}
                      name={[
                        index,
                        chunk.type === "subtitle"
                          ? "subtitlesText"
                          : "promptText",
                      ]}
                      rules={[
                        {
                          required: true,
                          message: `Please enter ${
                            chunk.type === "subtitle"
                              ? "subtitles"
                              : "video prompt"
                          }`,
                        },
                      ]}
                    >
                      <Input.TextArea
                        readOnly={videoStages.includes(currentStatus)}
                        value={
                          chunk.type === "subtitle"
                            ? chunk.subtitlesText
                            : chunk.promptText
                        }
                        onChange={(e) => {
                          const updatedChunks = [...chunks];
                          if (chunk.type === "subtitle") {
                            updatedChunks[index].subtitlesText = e.target.value;
                          } else {
                            updatedChunks[index].promptText = e.target.value;
                          }
                          setChunks(updatedChunks);
                        }}
                        placeholder={
                          chunk.type === "subtitle"
                            ? "Enter subtitle"
                            : "Enter prompt"
                        }
                      />
                    </Form.Item>
                  </Flex>
                  <Flex gap={20} justify="flex-end">
                    <Button onMouseDown={() => handleRemoveAnnotation(index)}>
                      Remove
                    </Button>
                  </Flex>
                </Flex>
              )}
              {/* Slide Input Handling */}
              {chunk.type === "slide" && (
                <Flex vertical gap={10} flex={1}>
                  <Flex vertical gap={1} align="flex-start" flex={1}>
                    <span>{chunk.type}</span>
                    <Form.Item
                      style={{ width: "100%" }}
                      required={false}
                      label={""}
                      name={[index, "slideNumber"]}
                      rules={[
                        {
                          required: true,
                          message: `Please select a slide number`,
                        },
                      ]}
                    >
                      <Select
                        disabled={videoStages.includes(currentStatus)}
                        style={{ height: 50, width: "100%" }}
                        options={generateOptions(pages)}
                        placeholder="Select slide number"
                        onChange={(val) => {
                          const updatedChunks = [...chunks];
                          updatedChunks[index].slideNumber = val;
                          setChunks(updatedChunks);
                        }}
                      />
                    </Form.Item>
                  </Flex>{" "}
                  {chunk?.slideNumber && (
                    <img
                      src={
                        slidePreview && slidePreview[chunk?.slideNumber]
                          ? slidePreview[chunk?.slideNumber]
                          : ""
                      }
                      alt=""
                    />
                  )}
                  {!videoStages.includes(currentStatus) && (
                    <Flex gap={20} justify="flex-end">
                      <Button onMouseDown={() => handleRemoveAnnotation(index)}>
                        Remove
                      </Button>
                    </Flex>
                  )}
                </Flex>
              )}
            </div>
          ))}
        </Flex>
      </Form>
    </div>
  );
};

export default LessonScriptUpdation;
