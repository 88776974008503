import React, { useState } from "react";
import "./ScriptVerification.css";
import TextArea from "antd/es/input/TextArea";
import { CopyOutlined } from "@ant-design/icons";
import { Button, Collapse, Flex, Input, Tooltip } from "antd";
import { fetchSuggestedScript } from "../../../api/hubSpotService";

const ScriptVerification = ({
  script,
  setScript,
  leadId = null,
  initialScript = null,
  setInitialScript,
  title,
  setTitle,
  user,
  type,
}) => {
  const [suggestedScripts, setSuggestedScripts] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchSuggestion = async () => {
    setIsLoading(true);
    try {
      const res = await fetchSuggestedScript(leadId, script);
      setSuggestedScripts(res);
      initialScript === null && setInitialScript(script);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleCopytoClipboard = (e, suggestion) => {
    e.stopPropagation();
    navigator.clipboard.writeText(suggestion);
  };

  const getScriptLabel = (script) => {
    // return first 5 words of the script
    return script.split(" ").slice(0, 6).join(" ") + "...";
  };

  const getInitialScript = () => {
    setScript(initialScript);
  };

  return (
    <>
      {leadId && (
        <Flex vertical justify="flex-end" align="flex-end">
          <div className="button-wrapper">
            <Button
              type="primary"
              onClick={fetchSuggestion}
              disabled={!script || isLoading}
            >
              {isLoading
                ? "Generating..."
                : suggestedScripts
                ? "Regenerate"
                : "Get Suggestions"}
            </Button>
            <Button
              type="primary"
              onClick={getInitialScript}
              disabled={!initialScript}
            >
              Get First Script
            </Button>
          </div>
        </Flex>
      )}
      <div className="script-verification-container">
        {type !== "DIY" && (
          <Input
            placeholder="Enter the video title here"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        )}
        {/* <div className="title-input"><Input placeholder="Title of the Video" value={title} onChange={(e)=> setTitle(e.target.value)} /></div>   */}
        <div className="script-inputs">
          <TextArea
            placeholder="Enter the script to generate audio (min 6 words)"
            value={script}
            onChange={(e) => setScript(e.target.value)}
          />
          {leadId && (
            <div className="script-suggestion-container">
              {suggestedScripts?.length > 0 ? (
                <div className="script-suggestion">
                  <Collapse
                    defaultActiveKey={["0"]}
                    expandIconPosition="start"
                    items={suggestedScripts.map((suggestedScript, index) => ({
                      key: index,
                      label: getScriptLabel(suggestedScript),
                      children: <p>{suggestedScript}</p>,
                      extra: (
                        <Tooltip title="Copied" trigger="click">
                          <CopyOutlined
                            onClick={(e) => {
                              handleCopytoClipboard(e, suggestedScript);
                            }}
                            style={{
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                          />
                        </Tooltip>
                      ),
                    }))}
                  />
                </div>
              ) : (
                <div className="placeholder-wrapper">
                  <p className="placeholder-text">
                    Suggested Script will be here
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ScriptVerification;
