import React, { useContext } from "react";
import "./header.css";
import { Avatar, Dropdown, Flex, Layout, Skeleton } from "antd";
import { ReactComponent as MenuIcon } from "../../assets/images/menu.svg";
import { ReactComponent as Arrow } from "../../assets/images/downArrow.svg";
import AuthContext from "../../services/storageService";
import DashboardBreadcrumbs from "../breadcrumbs/dashBoardBreadCrumbs";
import { ReactComponent as Logo } from "../../assets/images/dashboardLogo.svg";
const { Header } = Layout;
const UserAvatar = ({
  backgroundColor = "random",
  label,
  size,
  src = label
    ? `https://ui-avatars.com/api/?name=${label}&background=${backgroundColor}&color=fff`
    : "",
  onAvatarClick = () => {},
  shape = "circle",
}) => {
  return (
    <Avatar
      shape={shape}
      src={src}
      size={size}
      onClick={onAvatarClick}
      icon={<MenuIcon />}
    />
  );
};
const DashboardHeader = ({ displayName, userDetails }) => {
  const auth = useContext(AuthContext);
  const handleMenuClick = (e) => {
    switch (e.key) {
      case "1":
        auth.logout();
        break;
      default:
        break;
    }
  };
  const items = [
    {
      key: "1",
      label: "Logout",
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  return (
    <Header
      style={{
        position: "sticky",
        top: 0,
        zIndex:999
      }}
    >
      <div className="header">
        <Flex align="center" classname="logo" gap={10}>
          <Logo />
        </Flex>
        <Flex className="navbar-menu">
          <Dropdown menu={menuProps}>
            {userDetails ? (
              <div className="user-details">
                <UserAvatar
                  size={35}
                  label={`${userDetails?.firstName} ${userDetails?.lastName}`}
                  backgroundColor="262528"
                />
                <span className="username">
                  {userDetails?.firstName} {userDetails?.lastName}
                </span>
                <Arrow />
              </div>
            ) : (
              <Flex className="user-details" align="center">
                <Skeleton.Avatar size={35} />
                <Skeleton.Button block size={35} style={{ width: "100%" }} />
                <Arrow />
              </Flex>
            )}
            
          </Dropdown>
        </Flex>
      </div>
    </Header>
  );
};

export default DashboardHeader;
