import React, { useEffect, useState } from "react";
import { Button, Flex, message, Skeleton, Steps } from "antd";
import CreateVideo from "../../generateVideo/createvideo/createVideo";
import ScriptVerification from "../../generateVideo/scriptVerification/ScriptVerification";
import AudioVerification from "../../generateVideo/audioVerification/audioVerification";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import {
  getContactById,
  getScriptQuestions,
} from "../../../api/hubSpotService";
import VideoSidebar from "../../sidebar/videoSidebar";
import ScriptCreation from "../scriptCreation/scriptCreation";
import TrainingScriptVerification from "../trainingScriptVerification/trainingScriptVerification";

const GenerateSteps = ({ videoType }) => {
  const { hubspotId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [initialScript, setInitialScript] = useState(null);
  const [title, setTitle] = useState("");
  const [script, setScript] = useState("");
  const [current, setCurrent] = useState(0);
  const [videoId, setVideoId] = useState("");
  const [isAllAudio, setIsAllAudio] = useState(false);
  const [avatarId, setAvatarId] = useState("");
  const [voiceId, setVoiceId] = useState("");
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentScript, setcurrentScript] = useState("");
  const [changed, setchanged] = useState(false);
  const [voiceChanged, setVoiceChanged] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [formData, setFormData] = useState();
  const [questionLoading, setQuestionLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [, , setDisplayName] = useOutletContext();
  const [disableNext, setDisableNext] = useState(false);
  const [finalChunks, setfinalChunks] = useState([]);
  const [videoStatus, setVideoStatus] = useState();

  useEffect(() => {
    if (videoType === "Personalized") {
      setDisplayName(["Contacts", "Create"]);
    } else if (videoType === "DIY") {
      setDisplayName(["DIY", "Create"]);
    } else {
      setDisplayName(["Training", "Create"]);
    }
  }, [videoType]);

  const next = () => {
    if (current === 0 || (current === 1 && videoType === "DIY")) {
      const wordCount = script.trim().split(/\s+/).length;
      if (wordCount < 6) {
        message.info("Please enter minimum 6 words");
      } else if (!title) {
        message.info("Please enter a title");
      } else {
        if (script !== currentScript) {
          setcurrentScript(script);
          setchanged(true);
          setUpdated(true);
        }
        setCurrent(current + 1);
      }
    } else {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const handleNavigation = () => {
    if (videoType === "Personalized") {
      navigate("/personalized-video");
    } else if (videoType === "Training") {
      navigate("/training-video");
    } else {
      navigate("/diy-video");
    }
  };
  const steps = [
    videoType === "DIY"
      ? {
          title: "Script Creation",
          content: (
            <ScriptCreation
              script={script}
              setScript={setScript}
              questions={questions}
              loading={questionLoading}
              setFormData={setFormData}
              formData={formData}
              setCurrent={setCurrent}
              setTitle={setTitle}
            />
          ),
        }
      : null,
    {
      title: "Script Verification",
      content:
        videoType === "Training" ? (
          <TrainingScriptVerification
            script={script}
            setScript={setScript}
            leadId={hubspotId}
            initialScript={initialScript}
            setInitialScript={setInitialScript}
            title={title}
            setTitle={setTitle}
            user={user}
            finalChunks={finalChunks}
            setfinalChunks={setfinalChunks}
          />
        ) : (
          <ScriptVerification
            script={script}
            setScript={setScript}
            leadId={hubspotId}
            initialScript={initialScript}
            setInitialScript={setInitialScript}
            title={title}
            setTitle={setTitle}
            user={user}
            type={videoType}
          />
        ),
    },
    {
      title: "Audio Verification",
      content: (
        <AudioVerification
          script={script}
          setScript={setScript}
          videoId={videoId}
          setVideoId={setVideoId}
          videoType={videoType}
          leadId={hubspotId}
          setIsAllAudio={setIsAllAudio}
          voiceId={voiceId}
          setUpdated={setUpdated}
          changed={changed}
          setchanged={setchanged}
          voiceChanged={voiceChanged}
          setVoiceChanged={setVoiceChanged}
          setDisableNext={setDisableNext}
          title={title}
        />
      ),
    },
    {
      title: "Generate Video",
      content: (
        <CreateVideo
          script={script}
          setScript={setScript}
          videoId={videoId}
          title={title}
          avatarId={avatarId}
          updated={updated}
          setUpdated={setUpdated}
          setGenerating={setGenerating}
          setVideoStatus={setVideoStatus}
        />
      ),
    },
  ].filter(Boolean);

  const items = steps.map((item, index) => ({
    key: item.title,
    title: item.title,
  }));

  const handleNextDisable = () => {
    if (videoType === "DIY") {
      if (current === 1 && !script) return true;
      if (current === 2 && !isAllAudio) return true;
    } else {
      if (current === 0 && !script) return true;
      if (current === 1 && !isAllAudio) return true;
    }
    return false;
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await getContactById(hubspotId);
        setUser(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    if (hubspotId) {
      setLoading(true);
      fetchUser();
    }
  }, [hubspotId]);

  useEffect(() => {
    if (videoType === "DIY") {
      setQuestionLoading(true);
      getScriptQuestions()
        .then((res) => {
          setQuestionLoading(false);
          console.log(res);
          setQuestions(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [videoType]);
  return (
    <Flex gap={50}>
      <VideoSidebar
        voiceId={voiceId}
        avatarId={avatarId}
        setVoiceId={setVoiceId}
        setAvatarId={setAvatarId}
        setVoiceChanged={setVoiceChanged}
        current={current}
      />
      <Flex flex={1} vertical>
        {loading ? (
          <Skeleton
            paragraph={{
              rows: 2,
            }}
          />
        ) : (
          <Flex className="conatct-details" vertical gap={0} align="flex-start">
            <h1 style={{ margin: 0 }}>
              {user?.firstname} {user?.lastname}
            </h1>
            {user?.mbtiProfile?.communicationStyle && (
              <div className="communication-style">
                <h3>Communication Style</h3>
                <span>{user?.mbtiProfile?.communicationStyle}</span>
              </div>
            )}
          </Flex>
        )}
        <Steps
          current={current}
          items={steps.map((step) => ({ title: step?.title }))}
        />
        <>{steps[current]?.content}</>
        <div
          style={{
            textAlign: "end",
            marginTop: "20px",
          }}
        >
          {current > 0 && !videoStatus && (
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => prev()}
              disabled={generating || disableNext}
            >
              Previous
            </Button>
          )}
          {current < steps.length - 1 &&
            (videoType !== "DIY" || current !== 0) && (
              <Button
                type="default"
                onClick={() => next()}
                disabled={handleNextDisable() || disableNext}
              >
                Proceed
              </Button>
            )}

          {current === steps.length - 1 && (
            <Button
              type="default"
              onClick={() => {
                handleNavigation();
              }}
              disabled={videoStatus!=="completed"}
            >
              Done
            </Button>
          )}
        </div>
      </Flex>
    </Flex>
  );
};
export default GenerateSteps;
