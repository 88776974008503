import React, { useEffect, useRef, useState } from "react";
import "./trainingAudioVerification.css";
import { Button, Input, message } from "antd";
import {
  PlayCircleOutlined,
  ReloadOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons";

import ReactAudioPlayer from "react-audio-player";
import { LoadingOutlined } from "@ant-design/icons";
import {
  deleteAudios,
  generateAudio,
  generateAudioBatch,
} from "../../../api/hubSpotService";
import NotificationInstance from "../../../services/notificationServices";

const TrainingAudioVerification = ({
  script,
  chunks,
  setChunks,
  videoId,
  setVideoId,
  videoType,
  leadId = null,
  setIsAllAudio,
  voiceId,
  setUpdated,
  changed,
  setchanged,
  voiceChanged,
  setVoiceChanged,
  setDisableNext,
  title
}) => {  
  // const [chunks, setChunks] = useState([]);
  const [audioUrls, setAudioUrls] = useState([]);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [playingChunkIndex, setPlayingChunkIndex] = useState(0);
  console.log(audioUrls);
  

  let currentVideoId = "";
  const audioRefs = useRef([]);
  const generateAudioForChunk = async (chunk, index) => {
    setDisableNext(true);
    const body = {
      leadId: leadId,
      videoId: currentVideoId ? currentVideoId : videoId,
      videoType: videoType,
      order: index.toString(),
      text: chunk,
      voiceId: voiceId,
    };

    try {
      const response = await generateAudio(body);
      if (response && response.data) {
        currentVideoId = response.data.videoId;
        setVideoId(response.info.videoId);
        setDisableNext(false);
        const { order, url } = response.data;
        return { order, url, videoId };
      } else {
        setDisableNext(false);
        console.error("Unexpected response format:", response);
        return { order: index, url: "" };
      }
    } catch (error) {
      setDisableNext(false);
      console.error("Error generating audio:", error);
      NotificationInstance.error({
        message: "Error generating audios",
        description: error.response.data.error,
      });
      return { order: index, url: "" };
    }
  };

  const generateAudioForChunksBatched = async (chunks) => {
    setDisableNext(true);
    const body = {
      leadId: leadId,
      videoId: currentVideoId ? currentVideoId : videoId,
      videoType: videoType,
      voiceId: voiceId,
      audioData: chunks,
      title:title
    };

    try {
      const response = await generateAudioBatch(body);
      if (response && response.data && Array.isArray(response.data)) {
        currentVideoId = response.info.videoId;
        setVideoId(response.info.videoId);
        setDisableNext(false);
        return response.data.map((item) => ({
          order: item.order,
          url: item.url,
          videoId: response.info.videoId,
        }));
      } else {
        console.error("Unexpected response format:", response);
        setDisableNext(false);
        return chunks.map(({ index }) => ({ order: index, url: "" }));
      }
    } catch (error) {
      setDisableNext(false);
      console.error("Error generating audio:", error);
      NotificationInstance.error({
        message: "Error generating audios",
        description: error.response?.data?.error || "Unknown error",
      });
      return chunks.map(({ index }) => ({ order: index, url: "" }));
    }
  };
  const deleteExistingAudios = async (c) => {
    const id = currentVideoId ? currentVideoId : videoId;
    if (id) {
      try {
        const response = await deleteAudios(id);
        if (response && response.data) {
          return true;
        } else {
          console.error("Unexpected response format:", response);
          return false;
        }
      } catch (error) {
        console.error("Error generating audio:", error);
        NotificationInstance.error({
          message: "Error generating audios",
          description: error.response.data.error,
        });
        return false;
      }
    } else {
      return false;
    }
  };
  const handleSentenceChange = (index, e) => {
    const newChunks = [...chunks];
    newChunks[index].text = e.target.value;
    setChunks(newChunks);
  };

  useEffect(() => {
    const initialAudioGeneration = async () => {
      try {
        // const chunksList = chunks.map((chunk, index) => ({ chunk, index }));
        const results = await generateAudioForChunksBatched(chunks);

        const audioUrls = results.map((result) => result.url);
        setAudioUrls(audioUrls);

        if (audioUrls.every((url) => url !== null && url !== "")) {
          setIsAllAudio(true);
        }
      } catch (error) {
        console.error("Error in initial audio generation:", error);
        NotificationInstance.error({
          message: "Error generating audios",
          description: "Failed to generate all audio chunks",
        });
      }
    };
    const clearAudios = async () => {
      try {
        const clear = await deleteExistingAudios(videoId);
        if (clear) {
          initialAudioGeneration();
        }
      } catch (err) {}
    };
    if (changed && videoId) {
      clearAudios();
      setchanged(false);
    } else {
      initialAudioGeneration();
      setchanged(false);
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (voiceChanged) {
      setAudioUrls([]);
      setVoiceChanged(false);
      NotificationInstance.info({
        message: "Audio Profile Changed",
        description: "All associated audios are being regenerated.",
      });

      const regenerateAudio = async () => {
        try {
          const chunk = chunks.map((chunk, index) => ({ chunk, index }));
          const results = await generateAudioForChunksBatched(chunk);
          const audioUrls = results.map((result) => result.url);
          setAudioUrls(audioUrls);
          message.success("Successfully regenrated audios");
        } catch (error) {
          message.error("Failed to regenerate audios. Please try again.");
          console.error("Error regenerating audios:", error);
        } finally {
        }
      };

      regenerateAudio();
    }
  }, [voiceChanged, chunks, generateAudioForChunk, setVoiceChanged]);

  const handlePlay = (index) => {
    if (audioUrls[index] === null) {
      generateAudioForChunk(chunks[index], index).then(({ url }) => {
        const newAudioUrls = [...audioUrls];
        newAudioUrls[index] = url;
        setAudioUrls(newAudioUrls);
        audioRefs.current[index].audioEl.current.play();
        setCurrentAudio(index);
        setPlayingChunkIndex(index);
      });
    }
    if (currentAudio === null) {
      setCurrentAudio(index);
      setPlayingChunkIndex(index);
      audioRefs.current[index].audioEl.current.play();
    } else if (currentAudio !== null && currentAudio !== index) {
      audioRefs.current[currentAudio].audioEl.current.pause();
      setCurrentAudio(index);
      setPlayingChunkIndex(index);
      audioRefs.current[index].audioEl.current.play();
    } else if (currentAudio === index) {
      setCurrentAudio(null);
      setPlayingChunkIndex(index);
      audioRefs.current[index].audioEl.current.pause();
    }
  };

  const handleRefresh = async (index) => {
    if (chunks[index]) {
      setAudioUrls((prev) => [
        ...prev.slice(0, index),
        null,
        ...prev.slice(index + 1),
      ]);
      setUpdated(true);
      const { order, url } = await generateAudioForChunk(chunks[index], index);
      const newAudioUrls = [...audioUrls];
      newAudioUrls[order] = url;
      setAudioUrls(newAudioUrls);
    } else {
      NotificationInstance.error({
        message: "Text Required",
        description: "Please enter text to generate audio.",
      });
    }
  };

  const handlePlayAllToggle = () => {
    if (currentAudio !== null) {
      audioRefs.current[currentAudio]?.audioEl?.current.pause();
      setCurrentAudio(null);
    } else if (currentAudio === null && playingChunkIndex !== null) {
      audioRefs.current[playingChunkIndex]?.audioEl?.current.play();
      setCurrentAudio(playingChunkIndex);
      audioRefs.current[playingChunkIndex].audioEl.current.onended = () => {
        playNext(playingChunkIndex, playingChunkIndex + 1);
      };
    } else if (currentAudio === null && playingChunkIndex === null) {
      audioRefs.current[0].audioEl.current.play();
      setPlayingChunkIndex(0);
      setCurrentAudio(0);
      audioRefs.current[0].audioEl.current.onended = () => {
        setCurrentAudio(null);
        playNext(0, 1);
      };
    }
  };

  const playNext = (current, next) => {
    if (audioUrls[next] === null) {
      return;
    }
    if (current === chunks.length - 1) {
      audioRefs.current[current].audioEl.current.pause();
      setCurrentAudio(null);
      setPlayingChunkIndex(null);
    } else if (current < chunks.length - 1) {
      // audioRefs.current[current].audioEl.current.pause();
      audioRefs.current[next]?.audioEl?.current?.play();
      setPlayingChunkIndex(next);
      setCurrentAudio(next);
      if (audioRefs.current[next].audioEl) {
        audioRefs.current[next].audioEl.current.onended = () => {
          playNext(next, next + 1);
        };
      }
    } else if (playingChunkIndex === chunks.length - 1) {
      audioRefs.current[playingChunkIndex].audioEl.current.pause();
      setPlayingChunkIndex(0);
      audioRefs.current[0].audioEl.current.play();
      setCurrentAudio(0);
      audioRefs.current[0].audioEl.current.onended = () => {
        playNext(0, 1);
      };
    }
  };

  const handleStartOver = () => {
    if (currentAudio !== null) {
      audioRefs.current[currentAudio].audioEl.current.pause();
      setCurrentAudio(null);
      setPlayingChunkIndex(null);
    }
    playNext(0, 0);
  };

  return (
    <>
      <div className="button-wrapper">
        <Button onClick={handlePlayAllToggle}>
          {currentAudio !== null ? "Pause All" : "Play All"}
        </Button>
        <Button onClick={handleStartOver}>Start Over</Button>
      </div>
      <div className="audio-verification-container">
        <Input.TextArea
          placeholder="Enter the script to generate audio"
          value={script}
          disabled
        />

        <div className="chunks-container">
          {chunks.map((sentence, index) => (
            <React.Fragment key={index}>
              <div className="chunk-container">
                <Input.TextArea
                  value={sentence.text}
                  variant="outlined"
                  size="large"
                  autoSize={{ minRows: 1, maxRows: 8 }}
                  onChange={(e) => handleSentenceChange(index, e)}
                />
                {audioUrls[index] ? (
                  currentAudio === index ? (
                    <PauseCircleOutlined
                      key={audioUrls[index]}
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                      onClick={() => handlePlay(index)}
                    />
                  ) : (
                    <PlayCircleOutlined
                      key={audioUrls[index]}
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                      onClick={() => handlePlay(index)}
                    />
                  )
                ) : (
                  <LoadingOutlined
                    disabled
                    style={{
                      fontSize: "20px",
                      cursor: "not-allowed",
                      marginLeft: "10px",
                    }}
                  />
                )}
                <ReloadOutlined
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                  onClick={() => handleRefresh(index)}
                />
              </div>
              {audioUrls[index] && (
                <ReactAudioPlayer
                  src={audioUrls[index]}
                  ref={(element) => (audioRefs.current[index] = element)}
                  controls
                  style={{
                    display: currentAudio === index ? "block" : "none",
                    minHeight: 54,
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default TrainingAudioVerification;
