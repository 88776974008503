import React, { useState, useRef } from "react";
import { Button, Input, Flex, Divider, Select, Form, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import "./trainingScriptVerification.css";
import NotificationInstance from "../../../services/notificationServices";
import UploadVideoPresentation from "../../modals/uploadVideoPresentation";
import { uploadPPT } from "../../../api/hubSpotService";

const TrainingScriptVerification = ({
  setScript,
  setfinalChunks,
  next,
  chunks,
  setChunks,
  title,
  setTitle,
  videoId,
  setVideoId,
  pages,
  setPages,
  file,
  setFile,
}) => {
  const [selectedChunkIndex, setSelectedChunkIndex] = useState(null);
  const [selectedText, setSelectedText] = useState("");
  const [currentSubtitle, setCurrentSubtitle] = useState("");
  const [currentPrompt, setCurrentPrompt] = useState("");
  const [isModalOpen, setIsModalOpen] = useState("");

  const [loading, setloading] = useState(false);
  const textAreaRefs = useRef([]);
  const [form] = Form.useForm();

  const handleTextSelection = (e, chunkIndex) => {
    const textarea = e.target;
    const selectedText = textarea.value.substring(
      textarea.selectionStart,
      textarea.selectionEnd
    );
    const wordCount = selectedText.trim().split(/\s+/).length;

    if (selectedText.length > 0 && wordCount >= 6) {
      setSelectedText(selectedText);
      setSelectedChunkIndex(chunkIndex);
    } else {
      setSelectedText("");
      setSelectedChunkIndex(null);
    }
  };

  const saveAnnotation = (type) => {
    if (type === "slide" && !file) {
      NotificationInstance.info({ message: "Please upload a Presentation" });
    } else {
      if (selectedText && selectedChunkIndex !== null) {
        const chunk = chunks[selectedChunkIndex];
        const newChunks = [];
        const startIndex = chunk.text.indexOf(selectedText);
        const endIndex = startIndex + selectedText.length;

        if (startIndex > 0) {
          const preText = chunk.text.substring(0, startIndex).trim();
          if (preText) {
            newChunks.push({
              text: preText,
              type: "normal",
              order: newChunks.length,
            });
          }
        }

        newChunks.push({
          text: selectedText,
          type: type,
          order: newChunks.length,
          subtitlesText: type === "subtitle" ? currentSubtitle : null,
          promptText: type === "video" ? currentPrompt : null,
          slideNumber: type === "slide" ? 1 : null,
        });

        if (endIndex < chunk.text.length) {
          const postText = chunk.text.substring(endIndex).trim();
          if (postText) {
            newChunks.push({
              text: postText,
              type: "normal",
              order: newChunks.length,
            });
          }
        }

        const updatedChunks = [
          ...chunks.slice(0, selectedChunkIndex),
          ...newChunks,
          ...chunks.slice(selectedChunkIndex + 1),
        ];

        // Update order for all chunks
        const orderedChunks = updatedChunks.map((c, index) => ({
          ...c,
          order: index,
        }));

        setChunks(orderedChunks);
        console.log(orderedChunks);

        form.setFieldsValue({ title: title, ...orderedChunks });
        setSelectedText("");
        setSelectedChunkIndex(null);

        if (type === "subtitle") {
          setCurrentSubtitle("");
        } else {
          setCurrentPrompt("");
        }
      }
    }
  };

  const handleRemoveAnnotation = (index) => {
    const chunk = chunks[index];
    const updatedChunks = [
      ...chunks.slice(0, index),
      { text: chunk.text, type: "normal", order: index },
      ...chunks.slice(index + 1),
    ];

    // Update order and merge adjacent normal chunks
    const mergedChunks = [];
    updatedChunks.forEach((c, idx) => {
      if (
        mergedChunks.length > 0 &&
        c.type === "normal" &&
        mergedChunks[mergedChunks.length - 1].type === "normal"
      ) {
        mergedChunks[mergedChunks.length - 1].text += ` ${c.text}`;
      } else {
        mergedChunks.push({ ...c, order: mergedChunks.length });
      }
    });

    setChunks(mergedChunks);
    // form.setFieldsValue(mergedChunks);
    form.setFieldsValue({ title: title, ...mergedChunks });

    setSelectedText("");
    setSelectedChunkIndex(null);
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields().then((values) => {
        let processedChunks = [];

        chunks.forEach((chunk) => {
          if (chunk.type === "normal") {
            const splitTexts = chunk.text
              .split(/\n+/)
              .filter((text) => text.trim() !== "");
            splitTexts.forEach((text) => {
              processedChunks.push({
                ...chunk,
                text: text.trim(),
                order: processedChunks.length,
              });
            });
          } else if (
            chunk.type === "subtitle" ||
            chunk.type === "video" ||
            chunk.type === "slide"
          ) {
            processedChunks.push({
              ...chunk,
              text: chunk.text.replace(/\n+/g, " ").trim(),
              order: processedChunks.length,
            });
          }
        });

        const mergedScript = processedChunks
          .map((chunk) => chunk.text)
          .join(" ");

        setScript(mergedScript);
        setfinalChunks(processedChunks);
        next();
      });
    } catch (error) {
      console.log("Validation failed:", error);
    }
  };

  const generateOptions = (num) => {
    return Array.from({ length: num }, (_, i) => ({
      label: i + 1,
      value: i + 1,
    }));
  };
  const uploadFile = (formData) => {
    if (!title) {
      NotificationInstance.info({ message: "Please enter a title" });
      form
        .validateFields(["title"])
        .then((res) => {})
        .catch((err) => {});
    } else {
      uploadPPT("Training", title, formData, videoId)
        .then((res) => {
          setloading(false);
          setVideoId(res?.data?.videoId);
          setPages(res?.data?.totalSlides);
          setIsModalOpen(false);
          NotificationInstance.success({
            message: "File uploaded successfully",
          });
        })
        .catch((err) => {
          setloading(false);
          NotificationInstance.error({ message: "File upload failed" });
        });
    }
  };

  const handleFileUplaod = () => {
    if (!title) {
      NotificationInstance.info({
        message: "Please enter a title before uploading file",
      });
      form
        .validateFields(["title"])
        .then((res) => {})
        .catch((err) => {});
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <div className="script-verification-container training">
      <Flex gap={20} justify="space-between">
        <Flex gap={20}>
          <Tooltip
            title={!selectedText ? "Please select at least one sentence" : ""}
          >
            <Button
              onMouseDown={(e) => {
                e.preventDefault();
                saveAnnotation("subtitle");
              }}
              disabled={!selectedText}
            >
              Add subtitle
            </Button>
          </Tooltip>
          <Tooltip
            title={!selectedText ? "Please select at least one sentence" : ""}
          >
            <Button
              onMouseDown={() => saveAnnotation("video")}
              disabled={!selectedText}
            >
              Add video prompt
            </Button>
          </Tooltip>

          <Tooltip
            title={!selectedText ? "Please select at least one sentence" : ""}
          >
            <Button
              onMouseDown={() => saveAnnotation("slide")}
              disabled={!selectedText}
            >
              Add presentation
            </Button>
          </Tooltip>
        </Flex>
        <Button onMouseDown={() => handleFileUplaod()}>
          Upload presentation
        </Button>
        {isModalOpen && (
          <UploadVideoPresentation
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            handleCancel={() => setIsModalOpen(false)}
            handleOk={uploadFile}
            setFile={setFile}
            file={file}
            setloading={setloading}
            loading={loading}
          />
        )}
      </Flex>

      <Form
        form={form}
        style={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
        initialValues={{ title: title, ...chunks }}
      >
        <Form.Item
          style={{ width: "100%" }}
          required={false}
          label={""}
          name={"title"}
          rules={[
            {
              required: true,
              message: "Please enter title",
            },
          ]}
        >
          <Input
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            placeholder="Enter your title here"
          />
        </Form.Item>
        <Divider style={{ margin: " 0 0 10px" }} />

        <div
          style={{ display: "flex", gap: "10px" }}
          className="script-inputs training"
        >
          <Flex vertical gap={10} flex={1}>
            {chunks.map((chunk, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  gap: "20px",
                  background: `${chunk.type === "normal" ? "none" : "#eee"}`,
                  padding: `${chunk.type === "normal" ? "0" : "10px"}`,
                  borderRadius: 8,
                }}
              >
                <Flex flex={2}>
                  <Form.Item
                    style={{ width: "100%" }}
                    required={false}
                    label={""}
                    name={[index, "text"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter the script",
                      },
                      {
                        validator: (_, value) => {
                          if (value) {
                            const wordCount = value
                              .split(" ")
                              .filter((word) => word).length;

                            if (wordCount >= 6) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                new Error(
                                  "The script must be at least one full sentence or 6 words"
                                )
                              );
                            }
                          }

                          // If the value is empty, the first rule (required) will handle the error message.
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <TextArea
                      ref={(el) => (textAreaRefs.current[index] = el)}
                      value={chunk.text}
                      rows={6}
                      placeholder="Enter your script here"
                      style={{ position: "relative" }}
                      onMouseUp={(e) => handleTextSelection(e, index)}
                      onKeyUp={(e) => handleTextSelection(e, index)}
                      onChange={(e) => {
                        // Update the chunk's text as the user types
                        const updatedChunks = [...chunks];
                        updatedChunks[index].text = e.target.value;
                        setChunks(updatedChunks);
                      }}
                    />
                  </Form.Item>
                </Flex>
                {(chunk.type === "subtitle" || chunk.type === "video") && (
                  <Flex vertical gap={10} flex={1}>
                    <Flex vertical gap={1} align="flex-start" flex={1}>
                      <span>{chunk.type}</span>
                      <Form.Item
                        style={{ width: "100%" }}
                        required={false}
                        label={""}
                        name={[
                          index,
                          chunk.type === "subtitle"
                            ? "subtitlesText"
                            : "promptText",
                        ]}
                        rules={[
                          {
                            required: true,
                            message: `Please enter ${
                              chunk.type === "subtitle"
                                ? "subtitles"
                                : "video prompt"
                            }`,
                          },
                        ]}
                      >
                        <Input.TextArea
                          value={
                            chunk.type === "subtitle"
                              ? chunk.subtitlesText
                              : chunk.promptText
                          }
                          onChange={(e) => {
                            const updatedChunks = [...chunks];
                            if (chunk.type === "subtitle") {
                              updatedChunks[index].subtitlesText =
                                e.target.value;
                            } else {
                              updatedChunks[index].promptText = e.target.value;
                            }
                            setChunks(updatedChunks);
                          }}
                          placeholder={
                            chunk.type === "subtitle"
                              ? "Enter subtitle"
                              : "Enter prompt"
                          }
                        />
                      </Form.Item>
                    </Flex>
                    <Flex gap={20} justify="flex-end">
                      {/* <Button onMouseDown={() => saveAnnotation(chunk.type)}>
                      Save
                      </Button> */}
                      <Button onMouseDown={() => handleRemoveAnnotation(index)}>
                        Remove
                      </Button>
                    </Flex>
                  </Flex>
                )}
                {chunk.type === "slide" && (
                  <Flex vertical gap={10} flex={1}>
                    <Flex vertical gap={1} align="flex-start" flex={1}>
                      <span>{chunk.type}</span>
                      <Form.Item
                        style={{ width: "100%" }}
                        required={false}
                        label={""}
                        name={[index, "slideNumber"]}
                        rules={[
                          {
                            required: true,
                            message: `Please select a slide number`,
                          },
                        ]}
                      >
                        <Select
                          style={{ height: 50, width: "100%" }}
                          options={generateOptions(pages)}
                          placeholder="Select slide number"
                          onChange={(val) => {
                            const updatedChunks = [...chunks];
                            updatedChunks[index].slideNumber = val;
                            setChunks(updatedChunks);
                          }}
                        />
                      </Form.Item>
                    </Flex>
                    <Flex gap={20} justify="flex-end">
                      <Button onMouseDown={() => handleRemoveAnnotation(index)}>
                        Remove
                      </Button>
                    </Flex>
                  </Flex>
                )}
              </div>
            ))}
          </Flex>
        </div>
      </Form>
      <Divider style={{ margin: "0" }} />

      <Flex justify="flex-end">
        <Button type="primary" onClick={handleSubmit}>
          Proceed
        </Button>
      </Flex>
    </div>
  );
};

export default TrainingScriptVerification;
