import React, { useEffect, useState } from "react";
import { getAvatars } from "../../../api/hubSpotService";
import { Col, Flex, Row, Skeleton } from "antd";
import styles from "./avatarlist.module.css";

const AvatarList = ({ avatarId, setAvatarId }) => {
  const [avatars, setAvatars] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getAvatars()
      .then((res) => {
        console.log(res);
        setAvatars(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  return (
    <Col>
      <div className={styles["header"]}>
        <h3 className={styles["title"]}>Choose an Avatar</h3>
      </div>
      <Row gutter={[10, 10]} wrap gap="small" className={styles["avatar-list"]}>
        {loading
          ? Array.from({ length: 20 }).map((_, index) => (
              <Col span={12}>
                <Skeleton.Button
                  block
                  active
                  size={80}
                  style={{ width: "100%" }}
                />
              </Col>
            ))
          : avatars
              ?.sort((a, b) => a?.name?.localeCompare(b?.name))
              ?.map((item) => (
                <Col span={12}>
                  <Flex
                    vertical
                    gap={10}
                    onClick={() =>
                      setAvatarId((prevAvatarId) =>
                        prevAvatarId === item?.avatarId ? null : item?.avatarId
                      )
                    }
                    className={`${styles["avatar"]} ${
                      styles[
                        item?.avatarId === avatarId ? `selected-avatar` : ""
                      ]
                    } `}
                  >
                    <img
                      src={item.thumbnail}
                      alt={item?.name || "Avatar"}
                      className={styles["thumbnail"]}
                    />
                    <div className={styles["name"]}>{item?.name}</div>
                  </Flex>
                </Col>
              ))}{" "}
      </Row>{" "}
    </Col>
  );
};

export default AvatarList;
