import { Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { getContacts, syncData } from "../../api/hubSpotService";

const PersonalizedVideoUsers = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, , setDisplayName] = useOutletContext();
  useEffect(() => {
    setDisplayName(["Contacts"]);
  }, []);

  const columns = [
    {
      title: "Lead ID",
      dataIndex: "leadId",
      key: "leadId",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "First Name",
      dataIndex: "firstname",
      key: "firstname",
    },
    {
      title: "LinkedIn URLs",
      dataIndex: "linkedInProfile",
      key: "linkedInProfile",
      render: (text) =>
        text ? (
          <Link
            to={text ? text : "#"}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            View Profile
          </Link>
        ) : (
          "Not Available"
        ),
    },
  ];

  const handleRowClick = (record) => {
    navigate(`/personalized-video/new/${record?.leadId}`);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await getContacts();
      setData(res.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleRefresh = async () => {
    setLoading(true);
    try {
      await syncData();
      await fetchData();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Button
        className="refresh-user-list"
        type="primary"
        onClick={handleRefresh}
        style={{
          float: "inline-end",
          margin: "0px 16px 16px",
        }}
      >
        Refresh List
      </Button>
      <Table
        columns={columns}
        dataSource={data}
        onRow={(row) => {
          return {
            onClick: () => handleRowClick(row),
          };
        }}
        loading={loading}
        className="leads"
      />
    </>
  );
};

export default PersonalizedVideoUsers;
