import React, { useEffect, useState } from "react";
import { Col, ConfigProvider, Flex, Row, Steps } from "antd";
import {
  getScriptQuestions,
  verifyDemoToken,
} from "../../../api/hubSpotService";
import DiyQuestions from "../diyQuestions/diyQuestions";
import "./diySteps.css";
import DIYResult from "../diyResult/diyResult";
import DIYTitle from "../diyTitle/diyTitle";
import { PRIMARY_COLOR } from "../../../utils/colors";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { diy } from "../diy";
import Logo from "../../../assets/images/leaplerning.png";

const DIYSteps = () => {
  const [script, setScript] = useState("");
  const [current, setCurrent] = useState(0);
  const [title, setTitle] = useState("");
  const [questions, setQuestions] = useState([]);
  const [formData, setFormData] = useState();
  const [questionLoading, setQuestionLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const { demoId } = useParams();
  const token = searchParams.get("referenceToken");
  console.log(demoId, token);
  const location = useLocation();
  const { verified } = location.state || {};
  const [verifying, setVerifying] = useState(false);
  const [valid, setValid] = useState(false);
  const [videoId, setVideoId] = useState(false);
  const { language } = useParams();
  const [lang, setLang] = useState(language === "de" ? "de" : "en");

  const steps = [
    {
      title: diy[lang].videoTitle.title,
      content: (
        <DIYTitle
          title={title}
          setTitle={setTitle}
          setCurrent={setCurrent}
          lang={lang}
          diy={diy}
        />
      ),
    },
    {
      title: diy[lang].videoQuestion.title,
      content: (
        <DiyQuestions
          script={script}
          setScript={setScript}
          questions={questions}
          loading={questionLoading}
          setFormData={setFormData}
          formData={formData}
          setCurrent={setCurrent}
          title={title}
          demoId={demoId}
          token={token}
          setVideoId={setVideoId}
          lang={lang}
          diy={diy}
        />
      ),
    },
    {
      title: diy[lang].videoResult.title,
      content: <DIYResult videoId={videoId} lang={lang} diy={diy} />,
    },
  ].filter(Boolean);

  useEffect(() => {
    if (token) {
      setQuestionLoading(true);
      getScriptQuestions(token)
        .then((res) => {
          setQuestionLoading(false);
          console.log(res);
          setQuestions(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  useEffect(() => {
    if (token && !verified) {
      setVerifying(true);
      verifyDemoToken(demoId, token)
        .then((res) => {
          console.log(res);
          setVerifying(false);
          setValid(true);
        })
        .catch((err) => {
          console.log(err);
          setVerifying(false);
          setValid(false);
        });
    } else if (token && verified) {
      setValid(true);
    }
  }, [token, verified, demoId]);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"Inter", sans-serif',
          Button: {
            colorPrimary: PRIMARY_COLOR,
            colorPrimaryHover: PRIMARY_COLOR,
            colorPrimaryActive: PRIMARY_COLOR,
            primaryShadow: "none",
            controlHeight: 50,
            fontSize: 16,
            fontWeight: 600,
            borderRadis: 8,
          },
          Input: {
            // colorBorder: "transparent",
            colorBgContainerDisabled: "#FFFFFF",
            hoverBorderColor: PRIMARY_COLOR,
            activeBorderColor: PRIMARY_COLOR,
            activeShadow: "none",
            controlHeight: 60,
            colorTextPlaceholder: "#9EA5AD",
            colorTextDisabled: PRIMARY_COLOR,
            fontSize: 16,
            fontWeight: 600,
          },
        },
        components: {
          Steps: {
            colorPrimary: PRIMARY_COLOR,
            colorSplit: "transparent",
            padding: 0,
          },
        },
      }}
    >
      {/* <GoogleReCaptchaProvider reCaptchaKey="6LfRTRwqAAAAAA9-bFdontzkBUnmFRYmaKFc6ztl"> */}
      <div className="diy-bg" />
      <Flex align="center" className="diy-logo-container diy-logo">
        <img src={Logo} alt="" className="leap-learning" />
      </Flex>
      <Row gap={50} className="diy-form-container diy-home" align="center">
        <Col
          xxl={{ span: 10 }}
          xl={{ span: 15 }}
          md={{ span: 20 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          className="form-items"
        >
          {verifying ? (
            <Flex
              align="center"
              justify="center"
              flex={1}
              className="loader-wrap"
            >
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            </Flex>
          ) : valid ? (
            <Flex
              flex={1}
              vertical
              className="diy-steps"
              justify="center"
              gap={50}
            >
              <Steps
                responsive={false}
                current={current}
                items={steps.map((step) => ({ title: step?.title }))}
              />
              <>{steps[current]?.content}</>
            </Flex>
          ) : (
            <Flex vertical gap={30} className="diy-content" justify="center">
              <h1 className="diy-title">{diy[lang].invalid}</h1>
            </Flex>
          )}
        </Col>
      </Row>
      {/* </GoogleReCaptchaProvider> */}
    </ConfigProvider>
  );
};
export default DIYSteps;
