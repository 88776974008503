import React, { useEffect, useState } from "react";
import "./scriptCreation.css";
import { Button, Col, Flex, Form, Input, Row, Skeleton } from "antd";
import { endAt, limitToLast, onChildAdded, ref } from "firebase/database";
import { dataBase } from "../../firebase/firebase";
import { query } from "firebase/firestore";
import {
  getScripts,
  submitScriptResult,
} from "../../../api/hubSpotService";
import NotificationInstance from "../../../services/notificationServices";
import TextArea from "antd/es/input/TextArea";

const ScriptCreation = ({
  script,
  setScript,
  questions,
  loading,
  formData,
  setFormData,
  setCurrent,
  setTitle
}) => {
  const [completed, setCompleted] = useState(false);
  const [jobId, setJobId] = useState();
  const [generating, setGenerating] = useState(false);
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    setGenerating(true);
    const { title, ...rest } = values;
    const answers = Object.keys(rest).map((key) => ({
      questionId: key,
      answer: rest[key],
    }));
    const formData = { title, answers };
    setTitle(title)
    submitScriptResult(formData)
      .then((res) => {
        setJobId(res?.data?.jobId);
        setCompleted(true);
        setFormData(values);
      })
      .catch((err) => {
        console.log(err);
        setGenerating(false);
        NotificationInstance.info({ message: "Please try again later" });
      });
  };
  const handleFinishFailed = ({ errorFields }) => {
    if (errorFields?.length > 0) {
      const errorf = errorFields[0]?.name[0];
      console.log(errorf);
      form.scrollToField(errorf, {
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };
  useEffect(() => {
    let unsubscribe;
    if (jobId && completed) {
      const channelRef = query(
        ref(dataBase, `scriptgen/${jobId}`),
        endAt,
        limitToLast(1)
      );
      unsubscribe = onChildAdded(channelRef, (snapshot) => {
        const message = snapshot.val();
        if (message.status === "completed") {
          getScripts(jobId)
            .then((res) => {
              setGenerating(false);
              console.log(res);
              setScript(res.data.script);
              setCurrent((prev) => prev + 1);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [jobId, completed]);
  return (
    <Row align="center">
      <Col span={16}>
        {loading ? (
          <Flex vertical gap={30} align="flex-start" className="question-form">
            {Array.from({ length: 5 }).map((_, index) => (
              <Flex vertical style={{ width: "100%" }}>
                <Skeleton
                  block
                  active
                  size={80}
                  style={{ width: "100%" }}
                  paragraph={{ rows: 1 }}
                />
                <Skeleton.Button
                  block
                  active
                  size={100}
                  style={{ width: "100%" }}
                />
              </Flex>
            ))}
          </Flex>
        ) : (
          <Form
            form={form}
            onFinish={handleFinish}
            layout="vertical"
            className="question-form"
            onFinishFailed={handleFinishFailed}
            initialValues={formData}
          >
            <Flex vertical gap={10}>
              <Form.Item
                required={false}
                className="title"
                label={<span className="question">Title</span>}
                name={"title"}
                rules={[
                  {
                    required: true,
                    message: "Please enter title",
                  },
                ]}
              >
                <Input className="answer-input" />
              </Form.Item>
              {questions?.map((question, index) => {
                return (
                  <Form.Item
                    required={false}
                    key={index}
                    label={
                      <Flex vertical gap={6}>
                        <span className="index">
                          Question {question?.questionOrder} of{" "}
                          {questions?.length}
                        </span>
                        <span className="question">
                          {question?.questionText}
                        </span>
                      </Flex>
                    }
                    name={question?._id}
                    rules={[
                      {
                        required: true,
                        message: "This question is required",
                      },
                    ]}
                  >
                    <TextArea rows={3} className="answer-input" />
                  </Form.Item>
                );
              })}

              <Flex justify="flex-end" flex={1} gap={20}>
                <Form.Item>
                  <Button htmlType="submit">
                    {!generating ? (
                      script ? (
                        "Regenerate Script"
                      ) : (
                        "Generate Script"
                      )
                    ) : (
                      <div className="chat-loader"></div>
                    )}
                  </Button>
                </Form.Item>
                {script && (
                  
                  <Button onClick={() => setCurrent((prev) => prev + 1)}>
                    Next
                  </Button>
                )}
              </Flex>
            </Flex>
          </Form>
        )}
      </Col>
    </Row>
  );
};

export default ScriptCreation;
