import React, { useState, useEffect } from "react";
import { Button, Flex, Input, Spin } from "antd";

const DIYTitle = ({ title, setTitle, setCurrent, lang, diy }) => {
  const [error, setError] = useState(false);
  const handleNext = () => {
    console.log(title);
    if (title?.trim() !== "") {
      setCurrent((prev) => prev + 1);
    } else {
      setError(true);
    }
  };
  return (
    <Flex vertical gap={40} className="diy-container">
      <span className="diy-video-title">{diy[lang].videoTitle.label}</span>
      <Flex vertical gap={40} className="diy-title-wrapper">
        <Flex vertical>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder={diy[lang].videoTitle.placeholder}
          />
          {error && <span className="error">{diy[lang].videoTitle.error}</span>}
        </Flex>
        <Button onClick={handleNext} type="primary">
          {diy[lang].button.next}
        </Button>
      </Flex>
    </Flex>
  );
};

export default DIYTitle;
