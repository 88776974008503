import { Avatar, Button, Flex, Form, Input, Modal, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
import "./modal.css";
import { addNewUser, createVideoUseCase } from "../../api/adminService";
import NotificationInstance from "../../services/notificationServices";
import { videoTypes } from "../../utils/format";
import { getContacts } from "../../api/hubSpotService";

const customOption = (option) => {
  return (
    <Flex gap={10} align="center">
      <Avatar
        shape={"circle"}
        src={`https://ui-avatars.com/api/?name=${option.data.label}&background=101A24&color=fff`}
        size={30}
        // icon={<Logo />}
      />
      <Flex vertical>
        <b>{option.data.label}</b>
        <span role="img" aria-label={option.data.label}>
          {option.data.email}
        </span>
      </Flex>
    </Flex>
  );
};

const CreateVideoModal = ({ isModalOpen, handleOk, handleCancel }) => {
  const [Leads, setLeads] = useState([]);
  const [category, setCategory] = useState({
    value: null,
    error: false,
  });
  const [total, setTotal] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [initial, setinitial] = useState(true);
  const [type, setType] = useState({
    value: "SUPER_ADMIN",
    error: false,
  });
  const [orgRole, setOrgRole] = useState({
    value: "ADMIN",
    error: false,
  });
  const [disable, setDisable] = useState(false);
  const [form] = Form.useForm();
  const onSubmit = () => {
    form
      .validateFields()
      .then((val) => {
        setDisable(true);
        createVideoUseCase(val)
          .then((res) => {
            console.log(res);
            setDisable(false);
            form.resetFields();
            handleOk();
            NotificationInstance.success({
              message: "Successful",
              description: "Successfully created new video",
            });
          })
          .catch((err) => {
            console.log(err);
            setDisable(false);
            NotificationInstance.error({
              message: "Video creation failed",
              description: "Please try again.",
            });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onTypeChange = (value) => {
    setType(value);
  };
  const onModalClose = () => {
    setType();
    form.resetFields();
    handleCancel();
  };
  const fetchLeads = () => {
    getContacts("", "", "", currentPage)
      .then((res) => {
        console.log(res);

        const responseData = res?.data.map((item) => ({
          label: `${item.firstname} ${item.lastname}`,
          value: item._id,
          email: item.email,
        }));
        if (initial) {
          setLeads(responseData);
        } else {
          setLeads((prevCategories) => [...prevCategories, ...responseData]);
        }
        setTotal(res?.info?.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
    setinitial(false);
  };
  useEffect(() => {
    setLoading(true);
    fetchLeads();
  }, [currentPage]);

  const onscroll = (e) => {
    const { target } = e;
    const bottom =
      target.scrollHeight - target.scrollTop === target.clientHeight;
    if (bottom && Leads?.length < total) {
      setcurrentPage((prev) => prev + 1);
    }
  };
  return (
    <Modal
      centered
      width={500}
      title="Create New Video"
      open={isModalOpen}
      onCancel={onModalClose}
      footer={() => (
        <div className="action-buttons">
          <Button
            type="primary"
            onClick={onSubmit}
            disabled={disable}
            loading={disable}
          >
            Create Video
          </Button>
          <Button className="cancel-button" onClick={onModalClose}>
            Cancel
          </Button>
        </div>
      )}
    >
      <div className="title-input-wrapper">
        <Form
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          className="add-user"
        >
          <Form.Item
            name="title"
            label="Title"
            required={false}
            rules={[
              {
                required: true,
                message: "Please enter title",
                validateTrigger: onsubmit,
              },
            ]}
          >
            <Input placeholder="Enter the title" />
          </Form.Item>
          <Form.Item
            name="type"
            label="Video Type"
            required={false}
            rules={[
              {
                required: true,
                message: "Please select a video type",
                validateTrigger: onsubmit,
              },
            ]}
          >
            <Select
              placeholder="Select video type"
              optionFilterProp="children"
              onSelect={onTypeChange}
              variant="borderless"
              popupMatchSelectWidth={true}
              allowClear
              popupClassName="sort-options"
              options={videoTypes}
              onClear={() => setType()}
            />
          </Form.Item>
          {type === "PERSONALIZED" && (
            <Form.Item
              name="leadsId"
              label="Lead Name"
              required={false}
              rules={[
                {
                  required: true,
                  message: "Please select a lead",
                  validateTrigger: onsubmit,
                },
              ]}
            >
              <Select
                placeholder="Select a Lead"
                optionFilterProp="children"
                variant="borderless"
                popupMatchSelectWidth={true}
                allowClear
                popupClassName="sort-options"
                options={Leads}
                onPopupScroll={onscroll}
                value={category.value}
                loading={loading}
                optionRender={(option) => customOption(option)}
              />
            </Form.Item>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default CreateVideoModal;
