import React, { useEffect, useState } from "react";
import { getVoiceClones } from "../../../api/hubSpotService";
import { Button, Col, Flex, Row, Skeleton } from "antd";
import styles from "./audioList.module.css";
import { CaretRightOutlined, PauseOutlined } from "@ant-design/icons";

const AudioList = ({
  voiceId,
  setVoiceId,
  setVoiceChanged,
  current,
  diyVideo = false,
}) => {
  const [audio, setAudio] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPlaying, setCurrentPlaying] = useState(null);

  const handlePlay = (index) => {
    if (currentPlaying !== null && currentPlaying !== index) {
      document.getElementById(`audio-${currentPlaying}`).pause();
    }
    document.getElementById(`audio-${index}`).play();
    setCurrentPlaying(index);
  };

  const handlePause = (index) => {
    document.getElementById(`audio-${index}`).pause();
    setCurrentPlaying(null);
  };

  const handleEnded = () => {
    setCurrentPlaying(null);
  };
  useEffect(() => {
    setLoading(true);
    getVoiceClones()
      .then((res) => {
        console.log(res);
        setAudio(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  return (
    <Col>
      <div className={styles["header"]}>
        <h3 className={styles["title"]}>Choose an Audio Profile</h3>
      </div>
      <Row gutter={[10, 10]} wrap gap="small" className={styles["audio-list"]}>
        {loading
          ? Array.from({ length: 20 }).map((_, index) => (
              <Col span={24}>
                <Skeleton.Button
                  block
                  active
                  size={80}
                  style={{ width: "100%" }}
                />
              </Col>
            ))
          : audio
              ?.sort((a, b) => a?.name?.localeCompare(b?.name))
              ?.map((item, index) => (
                <Col span={24} key={index}>
                  <Flex
                    className={`${styles["audio"]} ${
                      styles[item?.voiceId === voiceId ? `selected-audio` : ""]
                    } `}
                    justify="space-between"
                    gap={10}
                    onClick={() => {
                      currentPlaying === index
                        ? handlePause(index)
                        : handlePlay(index);
                    }}
                  >
                    <Flex gap={10} align="center">
                      <audio
                        id={`audio-${index}`}
                        src={item.previewUrl}
                        onEnded={handleEnded}
                      ></audio>
                      <Button className={styles["play-control"]}>
                        {currentPlaying === index ? (
                          <PauseOutlined />
                        ) : (
                          <CaretRightOutlined />
                        )}
                      </Button>

                      <div className={styles["name"]}>{item.name}</div>
                    </Flex>
                    <Button
                      className={`${styles["select"]} ${
                        styles[item?.voiceId === voiceId ? `selected` : ""]
                      } `}
                      onClick={() => {
                        setVoiceId((prevVoiceId) =>
                          prevVoiceId === item?.voiceId ? null : item?.voiceId
                        );
                        setVoiceChanged(
                          diyVideo ? current === 2 : current === 1
                        );
                      }}
                    >
                      {item?.voiceId === voiceId ? "Selected" : "Select"}
                    </Button>
                  </Flex>
                </Col>
              ))}{" "}
      </Row>{" "}
    </Col>
  );
};

export default AudioList;
