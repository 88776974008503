import React, { useEffect, useState } from "react";
import JourneyHeader from "../../components/header/journeyHeader";
import "./courseGeneration.css";
import {
  Button,
  Col,
  ConfigProvider,
  Flex,
  Layout,
  Menu,
  notification,
  Row,
  Steps,
} from "antd";
import { PRIMARY_COLOR } from "../../utils/colors";
import { useNavigate, useParams } from "react-router-dom";
import { getUserDetails } from "../../api/profileServices";
import CourseLessons from "../../components/courseGenerations/courseLessons/courseLessons";
import CourseDetails from "../../components/courseGenerations/courseDetails/courseDetails";
import "./courseGeneration.css";
import {
  getCourseBriefingQuestions,
  getCourseDetails,
  getLessonAudios,
  getLessonBriefingQuestions,
  getLessonDetails,
} from "../../api/adminService";

import { endAt, limitToLast, onChildAdded, ref } from "firebase/database";
import { dataBase } from "../../components/firebase/firebase";
import { query } from "firebase/firestore";
import LessonDetails from "../../components/courseGenerations/lessonDetails/lessonDetails";
import { audioSection, audioStages, videoStages } from "../../utils/format";

const { Content } = Layout;
const CourseGeneration = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { courseIds } = params;
  const [api, contextHolder] = notification.useNotification();
  const [displayName, setDisplayName] = useState(["Courses", "New"]);
  const [userDetails, setuserDetails] = useState();
  const [type, setType] = useState("course");
  const [isDetailBoxOpen, setIsDetailBoxOpen] = useState(false);
  const [status, setStatus] = useState("created");
  const [sections, setSections] = useState([
    { steps: [{ title: "Questions" }, { title: "Course Brief" }] },
    {
      steps: [
        { title: "Brief" },
        { title: "Confirm Script" },
        { title: "Generate Video" },
      ],
    },
  ]);
  const [sectionStep, setsectionStep] = useState({
    id: 1,
    title: "Course Brief",
    status: "CREATED",
    script: null,
    type: "course",
  });
  const [steps, setSteps] = useState({
    course: [
      {
        id: 1,
        title:
          "Course Briefing - Please answer a few questions to help our AI auto-generate your course brief?",
        type: "form",
      },
      { id: 2, title: "Course Briefing", type: "script" },
      { id: 3, title: "Course Completion", type: "form" },
    ],
    lesson: [
      { id: 1, title: "Lesson Brief", type: "lessonForm" },
      { id: 2, title: "Lesson Script", type: "lessonScript" },
      { id: 3, title: "Generate Video", type: "lessonVideo" },
    ],
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [contentStep, setContentStep] = useState(0);
  const [courseId, setCourseId] = useState(courseIds ? courseIds : 0);
  const [lessonId, setLessonId] = useState(0);
  const [courseBrief, setCourseBreif] = useState();
  const [lessonBrief, setLessonBreif] = useState();
  const [lessons, setLessons] = useState([]);
  const [course, setCourse] = useState();
  const [courseListner, setCourseListner] = useState(false);
  const [videoIds, setvideoIds] = useState([]);

  const updateStep = (step, index) => {
    setContentStep(0);
    if (index !== sectionStep) {
      setIsDetailBoxOpen(false);
    }
    if (step !== "course") {
      setCurrentStep(1);
      setLessonId(index);
    } else {
      setCurrentStep(0);
      setLessonId();
    }
  };

  const updateStatus = (id, newStatus) => {
    if (sectionStep.id === id) {
      setsectionStep((prev) => ({ ...prev, status: newStatus }));
    }
    setLessons((prevLessons) =>
      prevLessons.map((lesson) =>
        lesson.id === id ? { ...lesson, status: newStatus } : lesson
      )
    );
  };

  const onChange = (value) => {
    if (type === "course" && course?.courseStage) {
      setContentStep(value);
    } else if (type === "lesson") {
      if (value === 1 && audioStages.includes(sectionStep.status)) {
        setContentStep(value);
      } else if (value == 2 && videoStages.includes(sectionStep.status)) {
        setContentStep(value);
      } else if (value === 0) {
        setContentStep(0);
      }
    }
  };
  useEffect(() => {
    getUserDetails()
      .then((res) => {
        setuserDetails(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getCourseBriefingQuestions()
      .then((res) => {
        setCourseBreif(res?.data);
      })
      .catch((err) => {});
  }, []);

  const fetchCourseDetail = ({ detail }) => {
    getCourseDetails(courseId)
      .then((res) => {
        setCourse(res.data);
        const videoIds = res?.data?.lessons
          .filter(
            (lesson) =>
              lesson.videoId &&
              lesson.lessonState !== "VIDEO_GENERATION_COMPLETED"
          )
          .map((lesson) => lesson.videoId);
        setvideoIds(videoIds);
        if (
          (detail || res?.data?.lessons) &&
          courseIds &&
          res?.data?.courseStage === "COURSE_BRIEFING_CONFIRMED"
        ) {
          setDisplayName(["Courses", res?.data?.title]);
          setLessons((prev) => [
            {
              id: 1,
              title: "Course Brief",
              script: null,
              type: "course",
              status: res?.data?.courseStage,
            },
            ...res?.data?.lessons.map((lesson) => ({
              ...lesson,
              status: lesson?.lessonState?.toUpperCase(),
              type: "lesson",
            })),
          ]);
          const lessonInProgress = res?.data?.lessons?.find(
            (lesson) => lesson.lessonState !== "VIDEO_GENERATION_COMPLETED"
          );
          if (lessonInProgress) {
            handleChangeSectionStep({
              ...lessonInProgress,
              type: "lesson",
              status: lessonInProgress?.lessonState,
            });
          }
        } else {
          if (res?.data?.courseStage !== "CREATED") {
            setCourseListner(false);
            setContentStep((prev) => prev + 1);
            updateStatus(1, "CONTENT_BRIEFING_GENERATED");
            setLessons((prev) => [
              {
                ...prev[0],
                id: 1,
                title: "Course Brief",
                status: "IN_PROGRESS",
              },
            ]);
          }
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (courseIds) {
      fetchCourseDetail({ detail: true });
    } else {
      setLessons([
        {
          id: 1,
          title: "Course Brief",
          status: "CREATED",
          script: null,
          type: "course",
        },
      ]);
    }
  }, [courseIds]);

  useEffect(() => {
    let unsubscribe;
    if (courseId && courseListner) {
      const channelRef = query(
        ref(dataBase, `course/${courseId}`),
        endAt,
        limitToLast(1)
      );
      unsubscribe = onChildAdded(channelRef, (snapshot) => {
        const message = snapshot.val();
        if (message?.status === "CONTENT_BRIEFING_GENERATED") {
          setTimeout(() => {
            fetchCourseDetail({ detail: true });
          }, 5000);
        } else {
        }
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [courseId, courseListner]);

  useEffect(() => {
    let unsubscribeFns = [];

    const listenToVideoProgress = (videoId) => {
      const channelRef = query(
        ref(dataBase, `leads/videos/${videoId}`),
        endAt,
        limitToLast(1)
      );
      const unsubscribe = onChildAdded(channelRef, (snapshot) => {
        const message = snapshot.val();
        console.log(message);

        if (message?.status === "completed") {
          console.log("completed", message);
          setvideoIds((prev) => prev.filter((id) => id !== message?.id));
          if (sectionStep.id !== message?.lessonId) {
            handleNotification(message?.lessonId);
          }
        }
      });

      return unsubscribe;
    };

    // Listen to each video ID
    videoIds.forEach((id) => {
      const unsubscribe = listenToVideoProgress(id);
      unsubscribeFns.push(unsubscribe); // Store each unsubscribe function
    });

    // Clean up all listeners on unmount or when videoIds change
    return () => {
      unsubscribeFns.forEach((unsubscribe) => {
        if (unsubscribe) {
          unsubscribe();
        }
      });
    };
  }, [videoIds, sectionStep]); // Trigger when any videoId changes

  useEffect(() => {
    if (courseId) {
      getLessonBriefingQuestions(courseId)
        .then((res) => {
          setLessonBreif(res?.data);
        })
        .catch((err) => {});
    }
  }, [courseId]);

  const updateLessons = (status) => {
    if (status !== "COMPLETED") {
      setLessons((prev) => [
        {
          ...prev[0],
          status: status,
        },
        ...course?.lessons.map((lesson) => ({
          ...lesson,
          status: lesson.lessonState.toUpperCase(),
          type: "lesson",
        })),
      ]);
    } else {
      if (courseIds) {
        setLessons((prev) => [
          {
            ...prev[0],
            status: "COURSE_BRIEFING_CONFIRMED",
          },
          ...prev.slice(1), 
        ]);
        handleChangeSectionStep({
          ...course?.lessons[0],
          status: course?.lessons[0]?.lessonState.toUpperCase(),
          type: "lesson",
        });
      } else {
        navigate(`/courses/${course?._id}`);
      }
    }
  };

  const handleChangeSectionStep = (item) => {
    console.log(item);
    setType(item.type);
    setsectionStep(item);
    setIsDetailBoxOpen(false);
    if (item.type === "course") {
      setContentStep(1);
      setCurrentStep(0);
      setLessonId();
    } else {
      updateLessonStep(item.status);
      setCurrentStep(1);
      setLessonId(item.id);
      if (audioSection.includes(item.status)) {
        setContentStep(1);
      } else if (videoStages.includes(item.status)) {
        setContentStep(2);
      } else {
        setContentStep(0);
      }
    }
  };

  const handleStatusChange = (type, id, status) => {
    if (type === "course") {
      setCourse((prev) => ({ ...prev, courseStage: status }));
    }
    if (sectionStep.id === id) {
      setsectionStep((prev) => ({ ...prev, status: status }));
    }
    setLessons((prevLessons) =>
      prevLessons.map((lesson) =>
        lesson.id === id ? { ...lesson, status: status } : lesson
      )
    );
  };

  const updateLessonStep = (status) => {
    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[1] = {
        steps: [
          { title: "Brief", disabled: false },
          {
            title: "Confirm Script",
            disabled: !audioStages.includes(status),
          },
          {
            title: "Generate Video",
            disabled: !videoStages.includes(status),
          },
        ],
      };

      return updatedSections;
    });
  };
  function addVideoId(videoId) {
    if (!videoIds.includes(videoId)) {
      videoIds.push(videoId);
      console.log(`Added videoId: ${videoId}`);
    } else {
      console.log(`videoId: ${videoId} already exists`);
    }
  }

  const handleNotification = (lessonId) => {
    const item = lessons?.find((item) => item.id === lessonId);
    const key = `notification_${lessonId}`; // Unique key for each notification
    if (item) {
      api.open({
        key: key,
        message: "Video Generation Completed",
        description: `Video generation for the lesson "${item.title}" completed successfully.`,
        btn: (
          <Button
            type="primary"
            onClick={() => {
              handleChangeSectionStep(item);
              api.destroy(key);
            }}
          >
            Go to Lesson
          </Button>
        ),
        duration: 0,
      });
    }
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"Poppins", sans-serif',
          Input: {
            colorBorder: "transparent",
            colorBgContainerDisabled: "transparent",
            hoverBorderColor: PRIMARY_COLOR,
            activeBorderColor: PRIMARY_COLOR,
            activeShadow: "none",
            controlHeight: 45,
            colorTextPlaceholder: "#9EA5AD",
          },
          InputNumber: {
            colorBorder: "transparent",
            colorBgContainerDisabled: "transparent",
            hoverBorderColor: PRIMARY_COLOR,
            activeBorderColor: PRIMARY_COLOR,
            activeShadow: "none",
            controlHeight: 45,
            colorTextPlaceholder: "#9EA5AD",
            controlWidth: 250,
          },
          Button: {
            colorPrimary: PRIMARY_COLOR,
            colorPrimaryHover: PRIMARY_COLOR,
            colorPrimaryActive: PRIMARY_COLOR,
            primaryShadow: "none",
            controlHeight: 45,
          },
        },
        components: {
          Layout: {
            headerBg: "#ffffff",
            headerPadding: "20px",
          },
          Tag: {
            defaultColor: PRIMARY_COLOR,
            defaultBg: "#B1F4DC",
          },
          Collapse: {
            contentBg: "#F5F7FA",
            borderRadiusLG: 8,
            headerPadding: 20,
            headerBg: "#f2f4f7",
            colorBorder: "transparent",
            contentPadding: "16px 0 0",
          },
          Menu: {
            darkItemBg: "#FFFFFF",
            darkItemColor: PRIMARY_COLOR,
            darkItemSelectedBg: PRIMARY_COLOR,
            darkItemSelectedColor: "#FFFFFF",
            itemMarginInline: "0",
            itemPaddingInline: 10,
            fontSize: 12,
            fontWeightStrong: 400,
            darkItemHoverColor: PRIMARY_COLOR,
            darkItemHoverBg: "#0000000f",
          },
          Drawer: {
            // footerPaddingBlock: 30,
            paddingLG: 10,
          },
          Steps: {
            colorPrimary: PRIMARY_COLOR,
            padding: 0,
          },
        },
      }}
    >
      <Layout>
        {contextHolder}
        <JourneyHeader
          displayName={displayName}
          userDetails={userDetails}
          type="course"
        />
        <Content
          style={{
            overflow: "initial",
          }}
        >
          <Row
            style={{
              maxHeight: "calc(100vh - 105px)",
              height: "calc(100vh - 105px)",
            }}
            align={"center"}
          >
            <Col
              span={5}
              style={{
                height: "100%",
              }}
            >
              <CourseLessons
                lessons={lessons}
                updateStep={updateStep}
                setType={setType}
                setsectionStep={setsectionStep}
                sectionStep={sectionStep}
                handleChangeSectionStep={handleChangeSectionStep}
              />
            </Col>
            {(courseIds && course) || !courseIds ? (
              <Col
                span={19}
                style={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <Flex
                  style={{
                    width: "100%",
                  }}
                >
                  <Row
                    align={"center"}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Col
                      span={12}
                      style={{
                        height: "100%",
                      }}
                    >
                      <div className="course-steps">
                        <Flex
                          vertical
                          // className="course-steps-container"
                          gap={20}
                        >
                          <Steps
                            responsive={false}
                            onChange={onChange}
                            current={contentStep}
                            size="small"
                            items={sections[currentStep].steps.map((step) => ({
                              title: step.title,
                              disabled: step?.disabled ?? false,
                            }))}
                          />
                        </Flex>
                      </div>
                    </Col>
                  </Row>
                </Flex>
                <Flex
                  flex={1}
                  style={{
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  <Row
                    align={"center"}
                    style={{
                      height: "100%",
                      maxHeight: "100%",
                      overflow: "hidden",
                      width: "100%",
                    }}
                  >
                    <Col
                      span={
                        type === "lesson" && contentStep === 1
                          ? 19
                          : isDetailBoxOpen
                          ? 24
                          : 15
                      }
                      style={{
                        height: "100%",
                      }}
                    >
                      <>
                        {type === "course" ? (
                          <CourseDetails
                            key={sectionStep?.id}
                            type={type}
                            questions={courseBrief}
                            step={steps[type][contentStep]}
                            setCourseId={setCourseId}
                            courseId={courseId}
                            course={course}
                            setCourse={setCourse}
                            setContentStep={setContentStep}
                            updateLessons={updateLessons}
                            sectionStep={sectionStep}
                            updateStatus={updateStatus}
                            setCourseListner={setCourseListner}
                          />
                        ) : (
                          <LessonDetails
                            key={sectionStep?.id}
                            type={type}
                            questions={lessonBrief}
                            step={steps[type][contentStep]}
                            setIsDetailBoxOpen={setIsDetailBoxOpen}
                            setCourseId={setCourseId}
                            courseId={courseId}
                            course={course}
                            setContentStep={setContentStep}
                            status={status}
                            setStatus={setStatus}
                            setCourse={setCourse}
                            updateLessons={updateLessons}
                            sectionStep={sectionStep}
                            updateStatus={updateStatus}
                            setLessonBreif={setLessonBreif}
                            isDetailBoxOpen={isDetailBoxOpen}
                            lessons={lessons}
                            setsectionStep={setsectionStep}
                            updateStep={updateStep}
                            lessonId={lessonId}
                            handleChangeSectionStep={handleChangeSectionStep}
                            setLessons={setLessons}
                            updateLessonStep={updateLessonStep}
                            addVideoId={addVideoId}
                          />
                        )}
                      </>
                    </Col>
                  </Row>
                </Flex>
              </Col>
            ) : (
              <Col span={19}></Col>
            )}
          </Row>
        </Content>
      </Layout>
    </ConfigProvider>
  );
};

export default CourseGeneration;
