import React, { useEffect, useState } from "react";
import "./TrainingVideoList.css";
import { Button, Table, Tag } from "antd";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getVideoList } from "../../api/hubSpotService";
import { timeCreatedOn } from "../../utils/format";
import VideoPlayer from "../../components/modals/videoPlayer";

const TrainingVideoList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selecteddata, setSelectedData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [, , setDisplayName] = useOutletContext();
  useEffect(() => {
    setDisplayName(["Training"]);
  }, []);
  const columns = [
    {
      title: "Video ID",
      dataIndex: "_id",
      key: "videoId",
    },
    {
      title: "Title",
      dataIndex: "script",
      key: "title",
      render: (text) =>
        text ? text?.split(" ").slice(0, 8).join(" ") + "..." : "No Title",
    },
    {
      title: "Video URL",
      dataIndex: "url",
      key: "url",
      render: (text, record) => {
        return record.status === "completed" ? (
          <span
            className="video-link"
            onClick={() => {
              setSelectedData(record);
              setIsOpen(true);
            }}
          >
            View
          </span>
        ) : (
          <Tag>{record.status}</Tag>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <p>{timeCreatedOn(text)}</p>,
    },
  ];

  const fetchVideoList = async () => {
    setIsLoading(true);
    try {
      const res = await getVideoList("Training");
      setData(res.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleAddVideo = () => {
    navigate("/training-video/new");
  };

  useEffect(() => {
    fetchVideoList();
  }, []);

  return (
    <>
      <Button
        className="add-training-video"
        type="primary"
        onClick={handleAddVideo}
      >
        Add Video
      </Button>
      {isOpen && (
        <VideoPlayer
          isOpen={isOpen}
          data={selecteddata}
          setIsOpen={setIsOpen}
          type={"Training"}
        />
      )}
      <Table columns={columns} dataSource={data} loading={isLoading} />
    </>
  );
};

export default TrainingVideoList;
